import React from 'react';
import axios from 'axios';
import TableResults from './TableResults';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faFileDownload, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, FormControl, InputGroup, Button, Spinner } from 'react-bootstrap';
// import SectionImportaCsv from '../sections/SectionImportaCsv';
import { FiltriAvanzati } from './FiltriAvanzati';
import moment from 'moment';
import { saveAs } from 'file-saver';
// import Select, { components } from 'react-select';
import Loader from '../components/Loader';
import * as config from '../config/config';



const loader = <Spinner animation="border" size="sm" role="status">
    <span className="sr-only">Loading...</span>
</Spinner>

class RicercaPtab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ptabData: null,
            loadPage: true, //TODO
            icon: faChevronDown,
            defaultVisibleAccordion: null,
            isLoading: false,
            isActiveSearch: false,
            estrazioneLoading: false,
            filters: [
                { name: 'triennio', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'matricola', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'cognome', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'nome', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'email', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'descr_corso', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                // { name: 'nome_corso', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'lingua', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'stato', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'stato_import', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'edizione', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'startDataCorso', value: '', advancedSearch: true, isRequiredFields: false, type: 'date' },
                { name: 'endDataCorso', value: '', advancedSearch: true, isRequiredFields: false, type: 'date' },
                { name: 'email_resp', value: '', advancedSearch: false, isRequiredFields: false, type: 'date' },
                { name: 'livello', value: '', advancedSearch: true, isRequiredFields: false, type: 'date' },
                { name: 'tipo_esito', value: '', advancedSearch: true, isRequiredFields: false, type: 'date' },
            ],
        }
    }

    componentDidMount() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({ isMobile: true })
        }

        axios.get(`${process.env.REACT_APP_API_URL}/getTrienni`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                this.setState({ trienni: resp.data, initalTriennio: '22/24' })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getCorsi`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                this.setState({ corsi: resp.data })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getStatiPtab`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                this.setState({ stati: resp.data })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getLivelli`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                this.setState({ livelli: resp.data })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getTipoEsito`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                this.setState({ tipiEsiti: resp.data })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })




        //storico filtri
        if (this.props.parentFilters && this.props.parentFilters.length > 0) {
            let searchParamsAndType = this.getSearchParamsAndType(this.props.parentFilters);
            //     let defaultVisibleAccordion = this.state.defaultVisibleAccordion;
            //     if (searchParamsAndType[0] === 'ricercaAvanzata') {
            //         defaultVisibleAccordion = "0";
            //     }
            this.setState({ filters: this.props.parentFilters }, () => {
                this.search();
            })
        } else {
            this.state.filters.find(f => f.name === 'stato').value = 'ATTIVO';
            this.state.filters.find(f => f.name === 'triennio').value = this.state.trienni && this.state.trienni[0].TRIENNIO ? this.state.trienni[0].TRIENNIO : this.getTriennio();
        }
    }

    onChangeValue = (event, name) => {
        let filters = this.state.filters;
        let specials = /[*|\":<>[\]{}`\\()';@&$]/;
        let currentLogo = this.state.currentLogo;
        if (name === 'lingua') currentLogo = event.target.value !== '' ? config.decodeFlag[event.target.value].logo : null;

        filters.find(f => f.name === name).value = event.target.value;
        this.setState({ filters: filters, currentLogo: currentLogo })
    }

    getTriennio = () => {
        //TO DO
        // let date = new Date();
        // let actualYear = date.getFullYear();
        // let actualMonth = date.getMonth();
        // let annoAccademicoCorrente = '';
        // switch(actualMonth) {
        //     case 0:
        //         annoAccademicoCorrente = actualYear 
        //       break;
        //     case 1:
        //         annoAccademicoCorrente = actualYear
        //       break;
        //     case 2:
        //         annoAccademicoCorrente = actualYear
        //     break;
        //     default:
        //         annoAccademicoCorrente = actualYear.toString() + '/' + (actualYear + 1).toString();
        //   }
        let triennio = '22/24';
        return triennio.toString();
    }

    handleAccordion = () => {
        let icon = this.state.icon === faChevronDown ? faChevronUp : faChevronDown;
        this.setState({ icon: icon })
    }

    clean = () => {
        let filters = this.state.filters;
        filters.forEach(f => f.name === 'stato' ? f.value = 'ATTIVO' : f.name === 'triennio' ? filters.find(f => f.name === 'triennio').value = this.state.initalTriennio : f.value = '')
        this.setState({ filters: filters, ptabData: null, selectedOptionValues: [], isActiveSearch: false, isLoading: false, currentLogo: '' })
    }

    search = () => {
        //TO DO

        this.setState({
            isLoading: true,
        })

        let filtersToCall = this.state.filters.map(function (f) {
            return {
                name: f.name,
                value: f.value,
                type: f.type
            }
        })

        let searchParamsAndType = this.getSearchParamsAndType(this.state.filters);
        axios.post(`${process.env.REACT_APP_API_URL}/${searchParamsAndType[0]}`, filtersToCall, this.props.authParam).then((resp) => {
            this.setState({ ptabData: resp.data, isLoading: false, isActiveSearch: true });
        }).catch((err) => {
            this.props.setErrors(err);
        })

    }

    getSearchParamsAndType = (filters) => {
        for (let i = 0; i < filters.length; i++) {
            if (filters[i].value !== '' && filters[i].advancedSearch) {
                return ['ricercaAvanzata'];
            }
        }

        return ['ricerca']
    }

    onChangePage = (actualNumberPage) => {
        let ptabData = this.state.ptabData;
        this.setState({ isLoading: true, ptabData: null })
        let ptabDataLength = this.state.ptabData ? this.state.ptabData.length : 0;
        if (ptabDataLength > 0) {
            let actualElements = actualNumberPage * this.state.paginationPerPage;
            setTimeout(() => {
                this.setState({ isLoading: false, ptabData: ptabData })
                console.log('actualElements', actualElements);
            }, 300)

        }
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    }

    changeDate = (date, type) => {
        let filters = this.state.filters;
        let dateFormatted = date ? date : '';
        if (type === 'S') filters.find(f => f.name === 'startDataCorso').value = dateFormatted; else filters.find(f => f.name === 'endDataCorso').value = dateFormatted;
        this.setState({ filters: filters })
    }

    callEstrazione = () => {
        let searchParamsAndType = this.getSearchParamsAndType(this.state.filters);
        let filtersToCall = this.state.filters.map(function (f) {
            return {
                name: f.name,
                value: f.value,
                type: f.type
            }
        })
        this.setState({ estrazioneLoading: true })
        axios.post(`${process.env.REACT_APP_API_URL}/callEstrazione${searchParamsAndType[0]}`, filtersToCall, {
            responseType: 'arraybuffer',
            headers: this.props.authParam.headers
        }).then((resp) => {
            this.setState({ estrazioneLoading: false })
            var blob = new Blob([resp.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(blob, `Estrazione PTAB_${moment(new Date()).format("DD MM YYYY hh:mm:ss")}.xlsx`);
        })
    }



    render() {

        return (
            <div className='mb-3' onKeyDown={this.onKeyDown}>
                {/* {!this.props.isReadOnly && !this.state.isMobile ? <SectionImportaCsv filters={this.state.filters} isActiveSearch={this.state.isActiveSearch} /> : null} */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <label className='customLabel' htmlFor="basic-url">Triennio</label>
                        <select className='form-control' onChange={(e) => this.onChangeValue(e, 'triennio')}
                            value={this.state.filters.find((f) => f.name === 'triennio').value}>
                            <option></option>
                            {this.state.trienni ? this.state.trienni.map(tr => <option value={tr.TRIENNIO} key={tr.TRIENNIO}>{tr.TRIENNIO}</option>) : <option></option>}
                        </select>
                    </Col>
                    <Col lg={1} className='mr-0 pr-0'>
                        <label className='customLabel' htmlFor="basic-url">Lingua</label>
                        <select id='lingua' className='form-control' onChange={(e) => this.onChangeValue(e, 'lingua')}
                            value={this.state.filters.find((f) => f.name === 'lingua').value}
                        >
                            <option value=''></option>
                            {this.state.corsi ? this.state.corsi.map(corso => <option value={corso.ACRONIMO_LINGUA} key={corso.ACRONIMO_LINGUA}>{corso.ACRONIMO_LINGUA}</option>) : <option></option>}
                        </select>
                    </Col>
                    {this.state.currentLogo ?
                        <Col className='d-flex align-items-center ml-1 pl-0 pt-3'>
                            <img src={this.state.currentLogo} />
                        </Col> : null}
                </Row>
                <Row>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Matricola</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                maxLength={6}
                                onChange={(e) => this.onChangeValue(e, 'matricola')}
                                value={this.state.filters.find((f) => f.name === 'matricola').value}
                                placeholder="Ricerca matricola"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Cognome</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'cognome')}
                                value={this.state.filters.find((f) => f.name === 'cognome').value}
                                placeholder="Ricerca cognome"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Nome</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'nome')}
                                value={this.state.filters.find((f) => f.name === 'nome').value}
                                placeholder="Ricerca nome"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Email</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'email')}
                                value={this.state.filters.find((f) => f.name === 'email').value}
                                placeholder="Email"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>

                </Row>

                <Row>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Descr. corso</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'descr_corso')}
                                value={this.state.filters.find((f) => f.name === 'descr_corso').value}
                                placeholder="Descrizione corso"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>
                    {/* <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Nome corso</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'nome_corso')}
                                value={this.state.filters.find((f) => f.name === 'nome_corso').value}
                                placeholder="Nome corso"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col> */}
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Edizione</label>
                        <select className='form-control' onChange={(e) => this.onChangeValue(e, 'edizione')}
                        value={this.state.filters.find((f) => f.name === 'edizione').value}
                        >
                            <option value=''></option>
                            <option value='1'>I EDIZIONE</option>
                            <option value='2'>II EDIZIONE</option>
                            <option value='3'>III EDIZIONE</option>
                        </select>
                    </Col>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Stato PTAB</label>
                        <select className='form-control' onChange={(e) => this.onChangeValue(e, 'stato')}
                            value={this.state.filters.find((f) => f.name === 'stato').value}
                        >
                            <option value=''></option>
                            {this.state.stati ? this.state.stati.map(stato => <option value={stato.DESCRIZIONE_STATO} key={stato.DESCRIZIONE_STATO}>{stato.DESCRIZIONE_STATO}</option>) : <option></option>}
                        </select>
                    </Col>

                </Row>
                {this.state.loadPage ?
                    <FiltriAvanzati
                        handleAccordion={this.handleAccordion}
                        onChangeValue={this.onChangeValue}
                        // onChangeValueSelect={this.onChangeValueSelect}
                        filters={this.state.filters}
                        icon={this.state.icon}
                        // date={this.state.date}
                        // changeDateImm={this.changeDateImm}
                        changeDate={this.changeDate}
                        tipiEsiti={this.state.tipiEsiti}
                        livelli={this.state.livelli}
                        defaultVisibleAccordion={this.state.defaultVisibleAccordion}
                    /> : null}
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Button className='mr-2' variant="primary" onClick={this.search}>Ricerca <FontAwesomeIcon icon={faSearch} /></Button>
                        <Button variant="secondary mr-2" disabled={!this.state.ptabData || this.state.ptabData.length === 0 || this.state.estrazioneLoading} onClick={this.callEstrazione}> Scarica risultati {this.state.estrazioneLoading ? loader : <FontAwesomeIcon icon={faFileDownload} />}</Button>
                        <Button variant="danger" onClick={this.clean}>Pulisci risultati <FontAwesomeIcon icon={faTrash} /></Button>
                    </Col>
                </Row>
                {this.state.ptabData ? <TableResults onChangePage={this.onChangePage} filters={this.state.filters} data={this.state.ptabData} isActiveSearch={this.state.isActiveSearch} paginationPerPage={this.state.paginationPerPage} /> : this.state.isLoading ? <Loader customStyle={{ marginTop: '5px' }} /> : null}

            </div>)
    }
}

export default withRouter(RicercaPtab);