import React, { Fragment } from 'react';
import { withRouter } from "react-router";
import { Button, Row, Col, Breadcrumb, Table, InputGroup, FormControl, ListGroup } from 'react-bootstrap';
import Loader from '../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faEraser, faEye, faEyeSlash, faHome, faInfoCircle, faLevelDownAlt, faList, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import _ from 'underscore';
import axios from 'axios';
import $ from 'jquery';



class Partecipanti extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            flagAddPartecipanti: false,
            flagDelete: false,
            elementToDelete: [],
            flagVisualizzaRicerca: false,
            partecipantiDaAgg: [],
            isPartecipanteDaAgg: false,
            ptabEmails: [],
            partecipantToAdd: [],
            valueSerched: '',
            textAreaValue: ''
        }
    }


    componentDidMount() {
        // TO DO call api per elenco partecipanti


        this.callPercorsoByID();



        // let i = 1;
        // let allTable = Array(this.props.location.state.percorso_form.nPartecipanti).fill().map(() => {
        //     return i++
        // });
        // let table1 = [];
        // let table2 = [];

        // for (let i = 0; i < allTable.length; i++) {
        //     i % 2 !== 1 ? table1.push(allTable[i]) : table2.push(allTable[i]);
        // }
        // this.setState({ table1: table1, table2: table2, loaded: true });
        this.setState({ loaded: true });
    }

    callPercorsoByID = (newIscritti = null) => {
        let params = {
            id: this.props.location.state.percorsoFormativo.ID_CORSO,
            email: newIscritti ? newIscritti.split(';') : this.props.location.state.percorsoFormativo.ISCRITTI.split(';')
        }
        axios.post(`${process.env.REACT_APP_API_URL}/getPercorsoById`, params, this.props.authParam).then((resp) => {
            if (resp) {
                this.callAllPart(resp.data);
                this.setState({ percorsoForm: _.sortBy(resp.data, 'COGNOME')})
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    callAllPart = (percorsoForm) => {
        axios.get(`${process.env.REACT_APP_API_URL}/getAllPartecipanti`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                let emails = percorsoForm.map(pf => pf.EMAIL);
                let newPartecipanti = _.difference(resp.data, emails).map((np, idx) => ({ id: idx, name: np }));
                this.setState({ allPartecipanti: resp.data, newPartecipanti: newPartecipanti })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    goHome = () => {
        this.setParentFilters().then(() => {
            this.props.history.go(-2);
        })
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    selectPTAB = (idx) => {
        let elementToDelete = this.state.elementToDelete;
        let percorsoForm = this.state.percorsoForm;

        if (elementToDelete.includes(percorsoForm[idx])) {
            elementToDelete = _.without(elementToDelete, percorsoForm[idx])
        } else {
            elementToDelete.push(percorsoForm[idx]);
        }


        this.setState({ elementToDelete: elementToDelete, flagDelete: elementToDelete.length > 0 })
    }

    deleteElement = () => {
        this.setState({ percorsoForm: null, isLoanding: true })
        let emailsPercorsoForm = this.state.percorsoForm.map(pF => pF.EMAIL)
        let emailsToDelete = this.state.elementToDelete.map(eD => eD.EMAIL);
        let elementToUpdate = _.difference(emailsPercorsoForm, emailsToDelete)
        let stringElemenetToUpdate = elementToUpdate.toString().replace(',', ';');
        let params = {
            desc_corso: this.props.location.state.percorsoFormativo.DESCR_CORSO,
            lingua: this.props.location.state.lingua,
            iscritti: stringElemenetToUpdate
        }
        axios.post(`${process.env.REACT_APP_API_URL}/deletePartecipantiToCourse`, params, this.props.authParam).then((resp) => {
            if (resp) {
                this.callPercorsoByID(stringElemenetToUpdate);
                this.closeNav();
                this.setState({ isLoanding: false })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    cleanResults = (type = null) => {

        if (type === 'cleanCheckbox') {
            $('input[type=checkbox]').prop('checked', true).each(function () {
                $(this).prop('checked', false);
            })
            this.setState({ elementToDelete: [], flagDelete: false })
        } else {
            $('.listGroup').each(function () {
                $(this).removeClass('activeListGroup');
            })
            this.setState({ isPartecipanteDaAgg: false })
        }

    }

    aggiungiPartecipanti = () => {
        this.openNav();
        this.setState({ flagAddPartecipanti: !this.state.flagAddPartecipanti })
    }

    reloadRicerca = () => {
        this.setState({ loading: true })
    }

    ricercaPartecipanti = () => {
        this.reloadRicerca();
        setTimeout(() => {
            this.setState({ flagVisualizzaRicerca: true, loading: false })
        }, "1000")

    }

    handlePartecipante = (e, idx) => {
        // alert('Hai selezionato: ' + )
        let newPartecipanti = this.state.newPartecipanti;
        let newPtabEmails = [];
        let ptabEmails = this.state.ptabEmails;
        let currentEl = this.state.ptabEmails[idx]

        newPtabEmails = _.without(ptabEmails, currentEl)
        newPartecipanti.push({ id: newPartecipanti.length, name: currentEl })

        this.setState({ ptabEmails: newPtabEmails, newPartecipanti: newPartecipanti })
    }

    openNav = () => {
        let self = this;
        $("#root").append("<div class='offcanvas-backdrop'></div>");
        $('.offcanvas-backdrop').on('click', function () {
            self.closeNav();
        });

        $('#mySidenav').addClass('show');
        $('#main').css({ 'margin-right': '20%' });
    }

    closeNav = () => {
        $('#mySidenav').removeClass('show');
        $('#main').css({ 'margin-right': '0' });
        $('.offcanvas-backdrop').remove();

        this.setState({ flagAddPartecipanti: !this.state.flagAddPartecipanti, flagVisualizzaRicerca: false, isPartecipanteDaAgg: false, valueSerched: '', ptabEmails: [], textAreaValue: '' })
    }

    handleOnSearch = (string, results) => {
        this.setState({ valueSerched: string })
    }

    handleOnSelect = (item) => {
        let ptabEmails = this.state.ptabEmails;
        let newPartecipantiArray = this.state.newPartecipanti.map(np => np.name);
        let refreshPartecipanti = _.without(newPartecipantiArray, item.name)
        ptabEmails.push(item.name)
        this.setState({ ptabEmails: ptabEmails, newPartecipanti: refreshPartecipanti.map((np, idx) => ({ id: idx, name: np })), valueSerched: '' })

    }

    aggiungiPartecipanteDB = () => {
        let actualEmails = this.state.percorsoForm.map(e => e.EMAIL);
        let ptabEmails = this.state.ptabEmails;
        if (actualEmails.length > 0) {
            ptabEmails = _.union(ptabEmails, actualEmails).toString();
        }


        let newIscritti = ptabEmails.toString().replaceAll(',', ';');
        //controllo se tutti i partecipanti non hanno già 3 edizioni attive per il triennio
        this.checkPartecipantiEdizioni(newIscritti).then(() => {
            //se 0 è possibile aggiungere tutti i partecipanti
            if (this.state.checNPartecipantiEdizioni.length === 0) {
                let params = {
                    desc_corso: this.props.location.state.percorsoFormativo.DESCR_CORSO,
                    iscritti: newIscritti,
                    triennio: this.props.location.state.percorsoFormativo.TRIENNIO,
                    n_ore: this.props.location.state.percorsoFormativo.N_ORE,
                    lingua: this.props.location.state.lingua
                }
                this.setState({ percorsoForm: null, isLoanding: true })
                axios.post(`${process.env.REACT_APP_API_URL}/updatePartecipantiToCourse`, params, this.props.authParam).then((resp) => {
                    if (resp) {
                        this.callPercorsoByID(newIscritti);
                        this.closeNav();
                        this.setState({ isLoanding: false })
                    }
                }).catch((err) => {
                    this.props.setErrors(err);
                })
            } else {
                alert(`Alcuni dei partecipanti che si sta provando ad inserire hanno già 3 versioni per l'anno accademico selezionato. Risolvi i problemi per: ${this.state.emailToSolve.toString()}`)
            }


        });
    }

    checkPartecipantiEdizioni = (newIscritti) => {
        let self = this;
        return new Promise((resolve, reject) => {
            let params = {
                emailToAdd: newIscritti,
                triennio: self.props.location.state.percorsoFormativo.TRIENNIO
            }
            axios.post(`${process.env.REACT_APP_API_URL}/checkPartecipanti`, params, self.props.authParam).then((resp) => {
                if (resp) {
                    self.setState({ checNPartecipantiEdizioni: resp.data.filter(el => el.count === 3), emailToSolve: resp.data.filter(el => el.count === 3).map(el => el.email) }, () => {
                        resolve();
                    })

                }
            }).catch((err) => {
                self.props.setErrors(err);
                reject()
            })
        })

    }

    onChangeTextArea = (e) => {
        let specials = /[*|\":<>[\]{}`\\()'&$]/;
        console.log('specials', !specials.test(e.target.value))
        if (!specials.test(e.target.value)) {
            let textAreaValue = this.state.textAreaValue;
            textAreaValue = e.target.value;
            this.setState({ textAreaValue: textAreaValue })
        }
    }

    bulkImport = () => {
        let check = this.checkEmails();
        if (check) {
            let actualEmails = this.state.percorsoForm.map(e => e.EMAIL);
            let textAreaValue = this.state.textAreaValue;
            if (actualEmails.length > 0) {
                textAreaValue = _.union(textAreaValue.split(';'), actualEmails).toString();
            }

            let params = {
                emails: textAreaValue.replaceAll(',',';'),
                desc_corso: this.props.location.state.percorsoFormativo.DESCR_CORSO
            }
            this.checkPartecipantiEdizioni(params.emails).then(() => {
                axios.post(`${process.env.REACT_APP_API_URL}/bulkImportCourse`, params, this.props.authParam).then((resp) => {
                    if (resp) {
                        this.callPercorsoByID(params.emails);
                        this.closeNav();
                        this.setState({ isLoanding: false })
                    }
                }).catch((err) => {
                    this.props.setErrors(err);
                })
            })
        } else {
            alert('Gli indirizzi email non sono validi')
        }
    }

    checkEmails = () => {
        let textAreaValue = this.state.textAreaValue.toLocaleLowerCase();
        if (textAreaValue.includes('@unimi.it') || textAreaValue.includes('@mi.infn.it')) {
            return true;
        }

        return false;
    }


    render() {
        return (
            this.state.loaded ? <div>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goHome}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={this.goBack}>
                        Nuovo Corso
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Partecipanti
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col xl={9}><h4><span className='customLabel'>Corso:</span> {this.state.percorsoForm && this.state.percorsoForm.length > 0 ? this.state.percorsoForm[0].DESCR_CORSO : this.props.location.state.percorsoFormativo.DESCR_CORSO} <img src={this.props.location.state.logo} /></h4></Col>
                    <Col className='text-right'><span className='customLabel'>
                        N° partecipanti:</span> {this.state.percorsoForm && this.state.percorsoForm.length > 0 ? this.state.percorsoForm.length : '0'}
                        <FontAwesomeIcon className='ml-1 cursorHover' icon={faCircle} size={'lg'} color={this.props.location.state.percorsoFormativo.IS_ACTIVE === 'Y' ? 'green' : 'red'} title={this.props.location.state.percorsoFormativo.IS_ACTIVE === 'Y' ? 'Corso attivo' : 'Corso non attivo'} />
                    </Col>
                </Row>
                <Row>
                    {this.state.percorsoForm && this.state.percorsoForm.length > 0 ? <Col xl={12} style={{ maxHeight: '450px', overflowY: 'auto' }}>
                        <Table className='mt-3' striped bordered hover size="sm">
                            <thead style={{ position: 'sticky', top: 0, background: 'white', backgroundColor: '#02336A', color: 'white' }}>
                                <tr>
                                    <th>#</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>E-mail</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {Array.from({ length: this.state.percorsoForm.length }).map((_, idx) => (
                                <Fragment key={idx}>
                                    <tbody>
                                        <tr key={idx + 1}>
                                            <td>{idx + 1}</td>
                                            <td>{this.state.percorsoForm[idx].COGNOME}</td>
                                            <td>{this.state.percorsoForm[idx].NOME}</td>
                                            <td>{this.state.percorsoForm[idx].EMAIL}</td>
                                            <td className='text-center cursorHover'><input id={'checkbox table-1 ' + idx} className='cursorHover checkbox' onClick={(e) => this.selectPTAB(idx)} type='checkbox' /></td>
                                        </tr>
                                    </tbody>
                                </Fragment>
                            ))}
                        </Table>
                    </Col> :
                        this.state.isLoanding ? <Loader customStyle={{ marginTop: '5px' }} /> :
                            <Col xl={12} className='mb-3 p-3 text-center'> <span>Non sono presenti partecipanti per questo percorso di Formazione.</span> </Col>}
                </Row>
                <div>Visualizza il personale Tecnico, Amministrativo e Bibliotecario presente sull'applicativo tramite il pulsante qui sotto <FontAwesomeIcon icon={faLevelDownAlt} /> </div>
                <Row className='text-center mt-3 mb-3'>
                    <Col>
                        <Button className='mr-2' variant='dark' onClick={!this.state.flagAddPartecipanti ? this.aggiungiPartecipanti : this.closeNav}>{!this.state.flagAddPartecipanti ? 'Aggiungi' : 'Nascondi'} PTAB <FontAwesomeIcon icon={!this.state.flagAddPartecipanti ? faEye : faEyeSlash} /></Button>
                        <Button className='mr-2' disabled={!this.state.flagDelete} onClick={() => this.cleanResults('cleanCheckbox')}>Pulisci selezione <FontAwesomeIcon icon={faEraser} /></Button>
                        <Button className='mr-2' disabled={!this.state.flagDelete} variant='danger' onClick={this.deleteElement}>Elimina partecipante/i <FontAwesomeIcon icon={faTrash} /></Button>

                    </Col>
                </Row>
                <div id="mySidenav" className="sidenav">
                    <a className="closebtn" onClick={this.closeNav}>&times;</a>
                    <h3>Aggiungi partecipanti</h3>
                    <div id='sidenavContainer' className='mt-5' style={{ maxWidth: '80%' }}>
                        <label className='customLabel' htmlFor="basic-url">Ricerca e-mail <FontAwesomeIcon icon={faSearch} /></label>
                        {/* <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.props.onChangeValue(e, 'email_responsabile')}
                                // value={this.props.filters.find((f) => f.name === 'email_responsabile').value}
                                placeholder="Email PTAB"
                                title="Digita qui l'email da ricercare"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup> */}
                        <div width='400px' id='customSearch' className='mb-3'>
                            <ReactSearchAutocomplete
                                items={this.state.newPartecipanti}
                                onSearch={this.handleOnSearch}
                                // onHover={handleOnHover}
                                onSelect={this.handleOnSelect}
                                styling={{ zIndex: 100 }}
                                inputSearchString={this.state.valueSerched}
                            // onFocus={handleOnFocus}
                            // autoFocus
                            // formatResult={formatResult}
                            />
                        </div>
                        <ListGroup className='text-center cursorHover mb-3'>
                            {/* {this.state.ptabEmails.map((ptabE, idx) => <ListGroup.Item id={'listGroup-' + idx} key={idx} onClick={(e) => this.handlePartecipante(e, idx)} className='pt-0 pb-0 m-0 listGroup'>{ptabE}</ListGroup.Item>)} */}
                            {this.state.ptabEmails.map((ptabE, idx) => <ListGroup.Item id={'listGroup-' + idx} key={idx} onClick={(e) => this.handlePartecipante(e, idx)} className='pt-0 pb-0 m-0 listGroup'>{ptabE} <FontAwesomeIcon className='text-right' color='red' icon={faTrash} /></ListGroup.Item>)}

                        </ListGroup>
                        <div className='text-center'>
                            <Button disabled={this.state.ptabEmails.length === 0} className='mr-1' size='sm' variant='success' onClick={this.aggiungiPartecipanteDB}>Aggiungi al corso</Button>
                        </div>
                        <div class="separator mt-4">Oppure</div>
                        <div className='mt-4'>
                            <label className='customLabel' htmlFor="basic-url">Bulk import <FontAwesomeIcon icon={faList} /></label> <FontAwesomeIcon className='cursorHover' color='dodgerblue' title="Per aggiungere massivamente: inserire indirizzi e-mail con separatore ;" icon={faInfoCircle} /> <br />
                            <textarea rows="4" cols="50" placeholder='esampio@unimi.it;esempio2@unimi.it;esempio3@unimi.it' value={this.state.textAreaValue} onChange={this.onChangeTextArea}>

                            </textarea>
                            <div className='text-center'>
                                <Button disabled={this.state.textAreaValue === ''} className='mr-1' size='sm' variant='info' onClick={this.bulkImport}>Bulk import</Button>
                            </div>
                        </div>

                    </div>
                </div>


            </div > : null)
    }
}

export default withRouter(Partecipanti);