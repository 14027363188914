import React, { Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './sections/Header';
import RicercaPtab from './components/RicercaPtab';
import AdminPage from './components/AdminPage';
import DettaglioPTAB from './components/DettaglioPTAB';
import Partecipanti from './components/Partecipanti';
import AuthPage from './components/AuthPage';
import axios from 'axios';

import { Container } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import NuovoCorso from './components/NuovoCorso';
import ImportaPTAB from './components/ImportaPTAB';
import ImportaEsiti from './components/ImportaEsiti';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      user: null,
      ruolo: null,
      email: null,
      authParam: {
        headers: { 'Authorization': '' }
      },
      errors: [],
    }
  }

  componentDidMount() {
    //check ip
    // this.fakeAuth();
    this.auth();
  }

  auth = () => {
    let url = window.location.search;
    let queryString = new URLSearchParams(url);
    let ticket = queryString.get("ticket");
    //old
    // window.history.pushState({}, document.title, "/slam_studenti");
    window.history.pushState({}, document.title, "/");

    let sessionJwt = this.checkJwt();
    if (sessionJwt) {
      // this.checkExpiry(sessionJwt);
      this.getInfoUtente(sessionJwt);
    } else {
      if (ticket) {
        // this.setState({ticket: ticket})
        let param = {
          cas: `${process.env.REACT_APP_CAS}`,
          ticket: ticket,
          // urlApp: `${process.env.REACT_APP_API_URL}`
        }
        axios.post(`${process.env.REACT_APP_API_URL}/setJwt`, param).then((resp) => {
          let sessionJwt = resp.data;
          localStorage.setItem('jwt_PTAB', sessionJwt);
          this.setAuthParam(sessionJwt);
          this.getInfoUtente(sessionJwt);
        }).catch((err) => {
          this.setErrors(err);
        })
      } else {
        window.location.href = `${process.env.REACT_APP_CAS}/login?service=${process.env.REACT_APP_URL}`;
      }
    }
  }

  setAuthParam = (sessionJwt) => {
    let authParam = this.state.authParam;
    authParam.headers.Authorization = 'bearer ' + sessionJwt;
    this.setState({ authParam: authParam })
  }

  checkJwt = () => {
    let sessionJwt = localStorage.getItem('jwt_PTAB');
    if (sessionJwt) {
      this.setAuthParam(sessionJwt);
      return sessionJwt;
    }
    return null;
  }

  setFilters = (filters, isActiveSearch) => {
    if (!isActiveSearch) filters = [];
    this.setState({ parentFilters: filters })
  }

  setErrors = (err) => {
    let errors = this.state.errors;
    console.log('Error', err)
    if (err.response) {
      errors.push({ error: err.response.data, status: err.response.status });
    } else {
      errors.push({ error: err, status: 500 });
    }
    this.setState({ errors: errors, user: null })
  }

  logout = () => {
    localStorage.removeItem('jwt_PTAB');
    window.location.href = `${process.env.REACT_APP_CAS}/logout`;
    this.setState({ user: null })
  }

  getInfoUtente = (sessionJwt) => {
    axios.post(`${process.env.REACT_APP_API_URL}/getUtente`, { jwt: sessionJwt }, this.state.authParam).then((resp) => {
      let user = resp.data.userName;
      let ruolo = resp.data.ruolo;
      let email = resp.data.email;
      let matricola = resp.data.matricola;
      this.setState({ user: user, ruolo: ruolo, email: email, isReadOnly: ruolo !== 'W' && ruolo !== 'ADMINISTRATOR' && ruolo !== 'ADMIN', matricola: matricola })
    }).catch((err) => {
      this.setErrors(err);
    })
  }


  render() {
    return (
      <Container>
        {this.state.errors.length > 0 ? <div></div> : null}
        {/* <ErrorModal errors={this.state.errors} showModalConflitto={this.state.errors.length > 0} logout={this.logout} closeModalConflitto={() => this.setState({ errors: [] })} /> : null} */}
        {!this.state.user ?
          <Fragment>
            {/* <Loader /> */}
          </Fragment> :
          <Router>
            <Header matricola={this.state.matricola} user={this.state.user} ruolo={this.state.ruolo} filters={this.state.parentFilters} logout={this.logout}></Header>
            {/* <hr></hr> */}
            <Switch>
              {this.state.ruolo && this.state.ruolo !== 'N/A' ?
                <div>
                  <Route exact path="/" component={(props) => <RicercaPtab  {...props} authParam={this.state.authParam} setErrors={this.setErrors} isReadOnly={this.state.isReadOnly} parentFilters={this.state.parentFilters} />} />
                  <Route exact path="/details" component={(props) => <DettaglioPTAB {...props} authParam={this.state.authParam} setErrors={this.setErrors} isReadOnly={this.state.isReadOnly} setFilters={this.setFilters} />} />
                  <Route exact path="/importaPTAB" component={(props) => <ImportaPTAB {...props} authParam={this.state.authParam} setErrors={this.setErrors} isReadOnly={this.state.isReadOnly} setFilters={this.setFilters} />} />
                  <Route exact path="/importaEsiti" component={(props) => <ImportaEsiti {...props} authParam={this.state.authParam} setErrors={this.setErrors} isReadOnly={this.state.isReadOnly} setFilters={this.setFilters} />} />
                  <Route exact path="/nuovoCorso" component={(props) => <NuovoCorso {...props} authParam={this.state.authParam} setErrors={this.setErrors} isReadOnly={this.state.isReadOnly} setFilters={this.setFilters} />} />
                  <Route exact path="/adminPage" component={(props) => <AdminPage {...props} email={this.state.email} authParam={this.state.authParam} setErrors={this.setErrors} isReadOnly={this.state.isReadOnly} setFilters={this.setFilters} />} />
                  <Route exact path="/partecipanti" component={(props) => <Partecipanti {...props} authParam={this.state.authParam} setErrors={this.setErrors} isReadOnly={this.state.isReadOnly} setFilters={this.setFilters} />} />
                </div> :
                <div>
                  <Route exact path="/" component={(props) => <AuthPage {...props} authParam={this.state.authParam} setErrors={this.setErrors} />} />
                </div>
              }
            </Switch>
          </Router>}

      </Container>
    );
  }
}

export default App;
