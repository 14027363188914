import React, { Fragment } from 'react';
import { withRouter } from "react-router";
import { Button, Row, Col, Card, Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faGlobe, faHome, faPlus, faPencilAlt, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import AccordionTriennio from '../sections/AccordionTriennio';
import ModalAggiungiCorso from '../sections/ModalAggiungiCorso';
import Loader from '../components/Loader';
import axios from 'axios';
import * as config from '../config/config';
import _ from 'underscore';

class NuovoCorso extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lingueCorso: [],
            reloadPercorsoFormativo: false,
            showModalAggCorso: false,
            selectedLanguage: '',
            dataModal: {
                DESCR_CORSO: '',
                TRIENNIO: '',
                N_ORE: '',
                LINGUA: '',
                EDIZIONE: '',
                IS_ACTIVE: 'Y'
            },
            initialDataModal: {
                DESCR_CORSO: '',
                TRIENNIO: '',
                N_ORE: '',
                LINGUA: '',
                EDIZIONE: '',
                IS_ACTIVE: 'Y'
            },
            isEditable: true
        }
    }

    componentDidMount() {
        // console.log('el', this.state.lingueCorso[0].corsi.length)
        // console.log('group', _.groupBy(this.state.lingueCorso[0].corsi, 'triennio'))
        // console.log('numberOfGroup', Object.keys(_.groupBy(this.state.lingueCorso[0].corsi, 'triennio')) )

        // this.setState({lingueCorso: []})

        axios.get(`${process.env.REACT_APP_API_URL}/getCorsi`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                this.setState({ corsi: resp.data })
                this.callPercorsoFormativo(resp.data[0].ACRONIMO_LINGUA);
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })

    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }


    goHome = () => {
        this.setParentFilters().then(() => {
            this.props.history.push({ pathname: '/' })
        })
    }

    goToPercorso_form = (percorsoFormativo, lingua, logo) => {
        this.props.history.push({ pathname: '/partecipanti', state: { filters: this.props.location.state.filters, percorsoFormativo: percorsoFormativo, lingua: lingua, logo: logo } })
    }

    getNPartecipanti = (iscritti) => {
        let newIscritti = iscritti !== '' ? iscritti.split(';') : [];
        return newIscritti.length;
    }

    callPercorsoFormativo = (percorso) => {
        this.setState({ reloadPercorsoFormativo: true })
        axios.get(`${process.env.REACT_APP_API_URL}/getPercorsoFormativo/${percorso}`, this.props.authParam).then((resp) => {
            this.setState({ percorsoFormativo: resp.data, reloadPercorsoFormativo: false, selectedLanguage: percorso, initialPercorsoFormativo: JSON.parse(JSON.stringify(resp.data)) })
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    openModalAggCorso = (row, action) => {
        let dataModal = this.state.dataModal;
        if (row) {
            dataModal.ID_CORSO = row.ID_CORSO;
            dataModal.DESCR_CORSO = row.DESCR_CORSO;
            dataModal.TRIENNIO = row.TRIENNIO;
            dataModal.N_ORE = row.N_ORE;
            dataModal.LINGUA = row.LINGUA;
            dataModal.EDIZIONE = row.EDIZIONE;
            dataModal.IS_ACTIVE = row.IS_ACTIVE
        }
        this.setState({ showModalAggCorso: true, dataModal: dataModal, action: action })
    }

    closeModalAggCorso = (isReloadPage) => {
        if (isReloadPage) this.reloadPage();
        this.setState({ showModalAggCorso: false, dataModal: JSON.parse(JSON.stringify(this.state.initialDataModal)), reloadPercorsoFormativo: false })
    }

    reloadPage = () => {
        this.callPercorsoFormativo(this.state.selectedLanguage)
    }

    modAccordion = (e) => {
        e.stopPropagation();
        //TO DO modal per modificare il triennio
    }

    deleteCourse = (corso) => {
        if (window.confirm("Sei sicuro di voler cancellare il corso " + corso.DESCR_CORSO + "?") === true) {
            let ID_CORSO = corso.ID_CORSO;
            axios.get(`${process.env.REACT_APP_API_URL}/deleteCourse/${ID_CORSO}`, this.props.authParam).then((resp) => {
                this.reloadPage();
            }).catch((err) => {
                this.props.setErrors(err);
            })
        }
    }

    onChangeSearch = (e, triennio) => {
        let percorsoFormativo = this.state.percorsoFormativo;
        let paramToSearch = e.target.value.toLowerCase();
        if (paramToSearch === ''){
            percorsoFormativo = JSON.parse(JSON.stringify(this.state.initialPercorsoFormativo));
            this.setState({ percorsoFormativo: percorsoFormativo })
        } else {
            let currentTriennio = this.state.percorsoFormativo.trienni[triennio].filter(p => p.DESCR_CORSO.toLowerCase().includes(paramToSearch))
            percorsoFormativo.trienni[triennio] = currentTriennio;
            this.setState({ percorsoFormativo: percorsoFormativo })
        }
    }


    render() {
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goHome}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Nuovo Corso
                    </Breadcrumb.Item>
                </Breadcrumb>
                {this.state.showModalAggCorso ? <ModalAggiungiCorso dataModal={this.state.dataModal} authParam={this.props.authParam} setErrors={this.props.setErrors} showModalAggCorso={this.state.showModalAggCorso} openModalAggCorso={this.openModalAggCorso} closeModalAggCorso={this.closeModalAggCorso} selectedLanguage={this.state.selectedLanguage} action={this.state.action} /> : null}
                {this.state.corsi ? <Fragment>

                    <Row>
                        <Col>
                            <div>
                                In questa sezione è possibile procedere alla visualizzazione e alla creazione dei corsi del personale Tecnico, Amministrativo e Bibliotecario.
                            </div>
                            {/* <Button variant='success' className='mt-3'>Aggiungi nuova lingua <FontAwesomeIcon icon={faGlobe} /></Button> */}
                            <Tabs
                                defaultActiveKey={this.state.corsi[0].ACRONIMO_LINGUA}
                                className="mb-3 mt-3"
                                onSelect={(k) => this.callPercorsoFormativo(k)}
                            >
                                {/* map lingue */}
                                {this.state.corsi.map((linguaCorso, idxLingua) =>
                                    <Tab key={idxLingua} className='p-3 mb-3' eventKey={linguaCorso.ACRONIMO_LINGUA} title={config.decodeFlag[linguaCorso.ACRONIMO_LINGUA].nome}>
                                        {!this.state.reloadPercorsoFormativo ? <Fragment>
                                            <Row><Col><span className='customLabel' style={{ fontSize: '25px' }}>{config.decodeFlag[linguaCorso.ACRONIMO_LINGUA].nome}</span> <img className='pb-2' src={config.decodeFlag[linguaCorso.ACRONIMO_LINGUA].logo} /></Col></Row>
                                            <Row xs={1} xl={1}>
                                                {/* map corsi */}
                                                {this.state.percorsoFormativo && Object.keys(this.state.percorsoFormativo.trienni).length > 0 ? Object.keys(this.state.percorsoFormativo.trienni).map((triennio, idx) =>
                                                    <Col key={idx}>
                                                        {/* <span className='customLabel'>Triennio {linguaCorso.trienni[idx].triennio} </span>
                                                <hr className='mb-2 mt-0'></hr> */}
                                                        <AccordionTriennio key={idx} triennio={triennio} modAccordion={this.modAccordion} isEditable={this.state.isEditable} linguaTriennio={linguaCorso.ACRONIMO_LINGUA} authParam={this.props.authParam}>
                                                            <div>
                                                                <input name="Ricerca Corso" placeholder='Ricerca' className="customSearch" onChange={(e) => this.onChangeSearch(e, triennio)} />
                                                                <FontAwesomeIcon className='blueUnimiColor' icon={faSearch} />
                                                            </div>
                                                            <Row sm={1} xl={3}>
                                                                {Array.from({ length: this.state.percorsoFormativo.trienni[triennio].length }).map((_, idx) => (
                                                                    <Col>
                                                                        <Card className='mb-3' border={'dark'}>
                                                                            <Card.Header className='blueLightUnimiBG' style={{ fontSize: '14px', fontWeight: 'bold', minHeight: '70px' }}> {this.state.percorsoFormativo.trienni[triennio][idx].DESCR_CORSO} </Card.Header>
                                                                            <Card.Body>
                                                                                <Card.Text>
                                                                                    <Row className='d-flex justify-content-between'>
                                                                                        <Col xl={10}><span className='customLabel'>N° Partecipanti:</span> {this.getNPartecipanti(this.state.percorsoFormativo.trienni[triennio][idx].ISCRITTI)}</Col>
                                                                                        {/* <Col onClick={() => this.goToPercorso_form(this.state.percorsoFormativo.trienni[triennio][idx].ID_CORSO, this.state.percorsoFormativo.trienni[triennio][idx].ISCRITTI, config.decodeFlag[linguaCorso.ACRONIMO_LINGUA].logo, this.state.percorsoFormativo.trienni[triennio][idx].DESCR_CORSO, linguaCorso.ACRONIMO_LINGUA) } className='cursorHover customLabel' xl={2}><FontAwesomeIcon title={'Visualizza partecipanti'} icon={faEye} /></Col> */}
                                                                                        <Col onClick={() => this.goToPercorso_form(this.state.percorsoFormativo.trienni[triennio][idx], linguaCorso.ACRONIMO_LINGUA, config.decodeFlag[linguaCorso.ACRONIMO_LINGUA].logo)} xl={2}><FontAwesomeIcon className='cursorHover customLabel' title={'Gestisci partecipanti'} icon={faEye} /></Col>
                                                                                    </Row>
                                                                                    <Row className='d-flex justify-content-between'>
                                                                                        <Col xl={10}> <span className='customLabel'>Ore corso:</span> {this.state.percorsoFormativo.trienni[triennio][idx].N_ORE}</Col>
                                                                                        <Col xl={2}><FontAwesomeIcon className='cursorHover customLabel mr-3' onClick={() => this.openModalAggCorso(this.state.percorsoFormativo.trienni[triennio][idx], 'M')} title={'Modifica info corso'} icon={faPencilAlt} /></Col>
                                                                                    </Row>
                                                                                </Card.Text>
                                                                            </Card.Body>
                                                                            <Card.Footer className='p-0'>
                                                                                <Button onClick={() => this.deleteCourse(this.state.percorsoFormativo.trienni[triennio][idx])} variant='danger' style={{ width: '100%', borderRadius: 0 }}>Cancella corso <FontAwesomeIcon icon={faTrash} /></Button>
                                                                            </Card.Footer>
                                                                        </Card>
                                                                    </Col>
                                                                ))}
                                                            </Row>

                                                        </AccordionTriennio >
                                                    </Col>
                                                ) : <Col xl={12} className='p-3 text-center'> <span>Non sono presenti corsi per questa Lingua, aggiungi nuovi corsi per visuallizarli.</span> </Col>}
                                            </Row>
                                            <Row className='text-center'>
                                                <Col>
                                                    <Button className='mt-3' onClick={() => this.openModalAggCorso(null, 'I')}>Aggiungi nuovo corso <FontAwesomeIcon icon={faPlus} /></Button>
                                                </Col>
                                            </Row>
                                        </Fragment> : <Loader customStyle={{ marginTop: '5px' }} />}
                                    </Tab>
                                )}
                            </Tabs>
                        </Col>
                    </Row>
                </Fragment> : <Loader customStyle={{ marginTop: '5px' }} />
                }
            </div>)
    }
}

export default withRouter(NuovoCorso);