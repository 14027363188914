import React, { Fragment } from 'react';
import { withRouter } from "react-router";
import { Button, Row, Col, Breadcrumb, ProgressBar, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSyncAlt, faDatabase, faCheck } from '@fortawesome/free-solid-svg-icons';
import papa from 'papaparse';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as config from '../config/config';

class ImportaEsiti extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            csvLoaded: false,
            taskCompleted: false
        }
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    goHome = () => {
        this.setParentFilters().then(() => {
            this.props.history.push({ pathname: '/' })
        })
    }


    handleFileSelected = (e) => {
        let self = this;
        let obj = {};
        let ptab = {}; let ptabToShow = {}
        let intestazione = [];
        papa.parse(e.target.files[0], {
            encoding: "ISO-8859-1",
            complete: function (results) {
                if (results.data.length > 0) {
                    for (let i = 0; i < results.data[0].length; i++) {
                        obj[results.data[0][i]] = ''
                    }
                    // intestazione = Object.keys(obj).map(int => ({ name: int.toUpperCase(), selector: int, cell: row => <div title={row[int]}>{row[int]}</div> }));
                    intestazione = [
                        {name: 'MATRICOLA', selector: 'matricola', maxWidth: '10px'},
                        {name: 'EMAIL', selector: 'email'},
                        {name: 'LIVELLO', selector: 'livello', maxWidth: '10px'},
                        {name: 'TIPO ESITO', selector: 'tipo_esito'},
                        {name: 'DATA SVOLGIMENTO', selector: 'data_svolgimento'},
                        {name: 'EDIZIONE', selector: 'edizione', maxWidth: '10px'},
                    ]
                    results.data.shift();

                    ptabToShow =  self.mapObject('show', results, obj);
                    ptab = self.mapObject('db', results, obj);
                }

                self.setState({ columnIntestazione: intestazione }, () => {
                    self.setState({ ptabEsiti: ptab, csvLoaded: true, ptabToShow: ptabToShow })
                })
            },
            skipEmptyLines: true
        })

    }

    mapObject = (type, results, obj) => {
        return results.data.map(function (r, idx) {
            Object.keys(obj).forEach((e, keys) => {
                let field = r[keys];
                if(e === 'livello') obj[e] = type === 'show' ? field : config.encodeLivello[field];
                else if(e === 'tipo_esito') obj[e] = type === 'show' ? field : config.encodeTipoEsito[field];
                else obj[e] = field
            })

            return JSON.parse(JSON.stringify(obj))
        })
    }

    saveData = () => {
        //chiamata api per salvare i dati
        this.setState({ isLoading: true, now: 45 })

        
        let params = this.state.ptabEsiti;
        axios.post(`${process.env.REACT_APP_API_URL}/saveEsito`, params, this.props.authParam).then((resp) => {
            if (resp && resp.data.status.toUpperCase() === 'OK') {
                
                setTimeout(()=> {
                    this.setState({isLoading: false, now: 100, taskCompleted: true, ptabEsiti: null})
                }, "3000")
                setTimeout(()=> {
                    this.setState({isLoading: false, now: 100, taskCompleted: null, csvLoaded: false, })
                }, "5000")
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })




  
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goHome}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Importa Esiti
                    </Breadcrumb.Item>
                </Breadcrumb>
                {!this.state.ptabEsiti ? <div className='mt-3'>
                    <h3>IMPORTA ESITI</h3>
                    <p>In questa sezione è possibile caricare il file per importare gli esiti. Il delimitatore sarà il ";"</p>
                </div> : null}

                {!this.state.csvLoaded ? <div><input className='form-control-file' onChange={this.handleFileSelected} type='file' id='files' accept='.csv' required></input></div> : <div></div>}

                {/* Risultati */}
                {this.state.ptabEsiti && this.state.ptabEsiti.length > 0 && !this.state.isLoading  ?
                    <div className='mb-2'>
                        <h3>Anteprima caricamento:</h3>
                        <DataTable
                            className={'tableCustomStyle mt-2'}
                            columns={this.state.columnIntestazione}
                            data={this.state.ptabToShow}
                            pagination={true}
                            paginationPerPage={5}
                            striped
                            // onChangePage={this.props.onChangePage}
                            highlightOnHover
                            // responsive
                            // conditionalRowStyles={this.conditionalRowStyles}
                        />
                        <Button onClick={this.saveData} className='mt-3 mr-2' variant='success'>Salva dati DB <FontAwesomeIcon icon={faDatabase} /></Button>
                        <Button onClick={() => this.setState({ csvLoaded: false, ptabEsiti: null })} className='mt-3' variant='primary'>Ricarica csv <FontAwesomeIcon icon={faSyncAlt} /></Button>

                    </div> : null}

                {this.state.isLoading ? <div className='text-center' style={{margin: '30px 20%'}}>
                    <ProgressBar now={this.state.now} label={`${this.state.now}%`} animated ></ProgressBar>
                </div> : null}


                {this.state.taskCompleted ? <Alert variant={'success'}>
                    Anagrafica/Anagrafiche caricata/e correttamente <FontAwesomeIcon icon={faCheck} />
                </Alert> : null}


            </Fragment>)
    }
}


export default withRouter(ImportaEsiti);