import React from 'react';
import { Modal, Button, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Fragment } from 'react';
import Loader from '../components/Loader';
import * as config from '../config/config';



class ModalAggiungiCorso extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            dataModal: props.dataModal
        }
    }

    componentDidMount() {
        // this.setState({dataModal: this.props.dataModal})
    }

    addCourse = () => {
        let params = {
            course: this.state.dataModal
        }
        params.course.LINGUA = this.props.selectedLanguage;

        let checkField = this.checkField(params);
        if (checkField) {
            axios.post(`${process.env.REACT_APP_API_URL}/addCourse`, params, this.props.authParam).then((resp) => {
                this.props.closeModalAggCorso(true);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        } else {
            alert('compila tutti i campi obbligatori')
        }

    }

    updateUser = () => {
        let params = {
            course: this.state.dataModal
        }
        params.course.LINGUA = this.props.selectedLanguage;

        let checkField = this.checkField(params);
        if (checkField) {
            axios.post(`${process.env.REACT_APP_API_URL}/updateCourse`, params, this.props.authParam).then((resp) => {
                this.props.closeModalAggCorso(true);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        } else {
            alert('compila tutti i campi obbligatori')
        }
    }

    checkField = (params) => {
        let obj = params.course;
        for (const prop in obj) {
            if (obj[prop] === '') return false;
        }

        return true;
    }



    onChangeValue = (e, name) => {
        let dataModal = this.state.dataModal;
        let specials = /[*|\"<>[\]{}`\\()';&$]/;
        if (!specials.test(e.target.value)) {
            dataModal[name] = e.target.value;
            this.setState({ dataModal: dataModal })
        }

    }

    closeModalAggCorso = () => {
        this.props.closeModalAggCorso();
    }



    render() {
        return (
            <Modal show={this.props.showModalAggCorso} onHide={() => this.closeModalAggCorso(false)} size="lg">
                <Modal.Header closeButton className='blueUnimiBG'>
                    <Modal.Title style={{ color: 'white' }}>
                        {this.props.action === 'M' ? "MODIFICA IL CORSO" : "AGGIUNGI UN NUOVO CORSO DI " + config.decodeFlag[this.props.selectedLanguage].nome.toUpperCase()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isLoading ?
                        <Loader /> :
                        this.state.dataModal ?
                            <Fragment>
                                <Row>
                                    <Col xl={6}>
                                        <label className='customLabel' htmlFor="basic-url">Nome corso:</label>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                aria-label="Default"
                                                onChange={(e) => this.onChangeValue(e, 'DESCR_CORSO')}
                                                value={this.state.dataModal.DESCR_CORSO}
                                                placeholder={`es. 2223 PTAB SLAM ${config.decodeFlag[this.props.selectedLanguage].nome.toLowerCase()} B1_1`}
                                                aria-describedby="inputGroup-sizing-default"
                                            // disabled = {this.props.action === 'M' || this.props.action === 'D' ? true : false}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <label className='customLabel' htmlFor="basic-url">Triennio:</label>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                aria-label="Default"
                                                onChange={(e) => this.onChangeValue(e, 'TRIENNIO')}
                                                value={this.state.dataModal.TRIENNIO}
                                                placeholder={'es. 22/24'}
                                                aria-describedby="inputGroup-sizing-default"
                                            // disabled = {this.props.action === 'M' || this.props.action === 'D' ? true : false}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <label className='customLabel' htmlFor="basic-url">N° ore:</label>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                aria-label="Default"
                                                onChange={(e) => this.onChangeValue(e, 'N_ORE')}
                                                value={this.state.dataModal.N_ORE}
                                                placeholder={'es. 10'}
                                                aria-describedby="inputGroup-sizing-default"
                                            // disabled = {this.props.action === 'M' || this.props.action === 'D' ? true : false}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <label className='customLabel' htmlFor="basic-url">Edizione:</label>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                aria-label="Default"
                                                onChange={(e) => this.onChangeValue(e, 'EDIZIONE')}
                                                value={this.state.dataModal.EDIZIONE}
                                                placeholder={'es. 1'}
                                                aria-describedby="inputGroup-sizing-default"
                                            // disabled = {this.props.action === 'M' || this.props.action === 'D' ? true : false}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <label className='customLabel' htmlFor="basic-url">Corso Attivo:</label>
                                        <select className='form-control' onChange={(e) => this.onChangeValue(e, 'IS_ACTIVE')} value={this.state.dataModal.IS_ACTIVE}>
                                            <option value='Y'>Y</option>
                                            <option value='N'>N</option>
                                        </select>
                                    </Col>
                                </Row>
                            </Fragment> : null}
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={() => this.closeModalAggCorso(false)}>
                        Annulla
                    </Button>
                    {this.props.action === 'I' ?
                        <Button variant="success" onClick={this.addCourse}>
                            Aggiungi
                        </Button> :
                        <Button variant="primary" onClick={this.updateUser}>Aggiorna</Button>}
                </Modal.Footer>
            </Modal>)
    }
}

export default ModalAggiungiCorso;