import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { Fragment } from 'react';
import Loader from '../components/Loader';


class ModalConflitto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            conflitti: null,
            isLoading: false,
            statiStudente: null,
            selectedStatoStudente: 'RINUNCIA',
            selectedPtab: [],
            isActiveChoice: true,
            isDoppiaCarriera: false
        }
    }

    componentDidMount() {
        let param = {
            MATRICOLA: this.props.ptab.anagrafica.MATRICOLA,
            EMAIL: this.props.ptab.anagrafica.EMAIL,
        }

        this.setState({ isLoading: true })
        axios.post(`${process.env.REACT_APP_API_URL}/getConflittiPTAB`, param, this.props.authParam).then((resp) => {
            this.setState({ conflitti: resp.data, isLoading: false })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        // axios.get(`${process.env.REACT_APP_API_URL}/getStatiConflitto`, this.props.authParam).then((resp) => {
        //     let stati = resp.data.filter(stato => stato.DESC_STATO !== 'ATTIVO');
        //     this.setState({ statiStudente: stati })
        // }).catch((err) => {
        //     this.props.setErrors(err);
        // })
    }

    onChangeValueStatoStudente = (e) => {
        this.setState({ selectedStatoStudente: e.target.value })
    }

    salvaConflitto = () => {
        let check = this.checkField();
        if (check) {
            let param = {
                // stato: this.state.selectedStatoStudente,
                ptab: this.state.selectedPtab,
                isActiveChoice: this.state.isActiveChoice,
                conflitti: this.state.conflitti,
                id_ptab: this.props.id_ptab,
                carriera: this.props.ptab,
            }
            axios.post(`${process.env.REACT_APP_API_URL}/saveConflitto`, param, this.props.authParam).then((resp) => {
                // this.setState({ conflitti: resp.data })
                let idStudente = resp.data.ID ? resp.data.ID : null;
                let codCdl = resp.data.COD_CDL ? resp.data.COD_CDL : null;
                this.props.hideAlertConflitto(true, idStudente, codCdl);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        } else {
            alert('Seleziona tutti i campi obbligatori')
        }
    }

    setCorrectPtab = (e, ptab) => {
        this.setState({ selectedPtab: ptab, isActiveChoice: e.currentTarget.value === 'attiva' })
    }

    checkField = () => {
        if ((this.state.selectedPtab === '' || this.state.selectedPtab.length === 0)) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <Modal show={this.props.showModalConflitto} onHide={this.props.closeModalConflitto} size="lg">
                <Modal.Header closeButton className='blueUnimiBG'>
                    <Modal.Title style={{ color: 'white' }}>
                        {this.props.ptab.anagrafica.COGNOME + " " + this.props.ptab.anagrafica.NOME}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isLoading ?
                        <Loader /> :
                        <Fragment>
                            <h5>Seleziona la riga corretta per risolvere il conflitto</h5>
                            <h6>Carriera ATTIVA:</h6>
                            <Table bordered>
                                <tbody>
                                    <tr>
                                        <td className={'blueUnimiBG'} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                            <label class="containerInputbox">
                                                <input onChange={(e) => this.setCorrectPtab(e, this.props.ptab.anagrafica)} type="radio" id="attiva" value={'attiva'} name="choice" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </td>
                                        <td style={{ maxWidth: '20rem' }}>
                                            <div><span className='customLabel'>MATRICOLA:</span> {this.props.ptab.anagrafica.MATRICOLA}</div>
                                            <div><span className='customLabel'>UFFICIO:</span> {this.props.ptab.anagrafica.UFFICIO}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <hr></hr>
                            <h6>CONFLITTI:</h6>
                            <Table bordered>
                                <tbody>
                                    {this.state.conflitti ? this.state.conflitti.map((conflitto, idx) =>
                                        <tr key={idx}>
                                            <td className={'blueUnimiBG'} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <label class="containerInputbox">
                                                    <input disabled = {this.state.isDoppiaCarriera} onChange={(e) => this.setCorrectPtab(e, conflitto)} type="radio" id={"inattiva_" + idx} value={'inattiva'} name={"choice"} />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </td>
                                            <td style={{ maxWidth: '20rem' }}>
                                                <div><span className='customLabel'>MATRICOLA:</span> {conflitto.MATRICOLA}</div>
                                                <div><span className='customLabel'>UFFICIO:</span> {conflitto.UFFICIO}</div>
                                            </td>
                                        </tr>) : null}
                                </tbody>
                            </Table>

                            
                        </Fragment>}
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={this.props.closeModalConflitto}>
                        Annulla
                    </Button>
                    <Button onClick={this.salvaConflitto} variant="primary">
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>)
    }
}

export default ModalConflitto;