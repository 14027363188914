import React, { Fragment } from 'react';
import axios from 'axios';
import { Button, Row, Col, Card, Breadcrumb, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard, faPencilAlt, faHome, faExclamationTriangle, faPlusSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from "react-router";
import AccordionTriennio from '../sections/AccordionTriennio';
import Loader from '../components/Loader';
import * as config from '../config/config';
import ModalConflitto from '../sections/ModalConflitto';
import ModalUserData from '../sections/ModalUserData';




class DettaglioPTAB extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            ptab: null,
            showModalConflitto: false,
            showModalMancante: false,
            showModalAdd: false,
            showModalUserData: false,
            typeModal: null,
            reloadPage: false,
            idDoppiaImmatricolazione: null,
            isEditable: false,
            loadTrienni: false,
            dataModal: {
                id_esito: '',
                anagrafica: {
                    fields: [
                        { name: 'STATO', label: 'Stato', value: '', type: 'optionStatiPtab', size: 6 },
                        { name: 'MATRICOLA', label: 'Matricola', value: '', type: 'input', size: 6, maxLength: 6 },
                        // { name: 'cdl', label: 'Corso di Laurea', value: '', type: 'input', size: 12 },
                        { name: 'COGNOME', label: 'Cognome', value: '', type: 'input', size: 6 },
                        { name: 'NOME', label: 'Nome', value: '', type: 'input', size: 6 },
                        // { name: 'cf', label: 'Codice Fiscale', value: '', type: 'input', size: 6 },
                        { name: 'EMAIL', label: 'E-mail', value: '', type: 'input', size: 6 },
                        { name: 'EMAIL_RESP', label: 'E-mail Responsabile', value: '', type: 'input', size: 6 },
                        { name: 'SETTORE', label: 'Settore', value: '', type: 'input', size: 6 },
                        { name: 'UFFICIO', label: 'Ufficio', value: '', type: 'input', size: 6 },
                        { name: 'STRUTTURA', label: 'Struttura', value: '', type: 'input', size: 6 },
                        // { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6 },
                        // { name: 'data_iscrizione', label: 'Data Immatricolazione', value: '', type: 'calendar', size: 6 },
                        // { name: 'convocazione', label: 'Convocazione PT', value: '', type: 'optionMailPT', size: 6 },


                    ],
                },
                esito: {
                    fields : [
                        { name: 'LIVELLO', label: 'Esito', value: '', type: 'optionLivelli', size: 6, isObbligatorio: true },
                        { name: 'TIPO_ESITO', label: 'Tipo esito', value: '', type: 'optionTipoEsito', size: 6, isObbligatorio: false },
                        { name: 'EDIZIONE', label: 'Edizione', value: '', type: 'optionEdizioni', size: 6, isObbligatorio: false },
                        { name: 'DATA_SVOLGIMENTO', label: 'Data', value: '', type: 'calendar', size: 6, isObbligatorio: true },
                    ]
                }
            },
            initialDataModal: {
                id_esito: '',
                anagrafica: {
                    fields: [
                        { name: 'STATO', label: 'Stato', value: '', type: 'optionStatiPtab', size: 6 },
                        { name: 'MATRICOLA', label: 'Matricola', value: '', type: 'input', size: 6, maxLength: 6 },
                        // { name: 'cdl', label: 'Corso di Laurea', value: '', type: 'input', size: 12 },
                        { name: 'COGNOME', label: 'Cognome', value: '', type: 'input', size: 6 },
                        { name: 'NOME', label: 'Nome', value: '', type: 'input', size: 6 },
                        // { name: 'cf', label: 'Codice Fiscale', value: '', type: 'input', size: 6 },
                        { name: 'EMAIL', label: 'E-mail', value: '', type: 'input', size: 6 },
                        { name: 'EMAIL_RESP', label: 'E-mail Responsabile', value: '', type: 'input', size: 6 },
                        { name: 'SETTORE', label: 'Settore', value: '', type: 'input', size: 6 },
                        { name: 'UFFICIO', label: 'Ufficio', value: '', type: 'input', size: 6 },
                        { name: 'STRUTTURA', label: 'Struttura', value: '', type: 'input', size: 6 },
                        // { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6 },
                        // { name: 'data_iscrizione', label: 'Data Immatricolazione', value: '', type: 'calendar', size: 6 },
                        // { name: 'convocazione', label: 'Convocazione PT', value: '', type: 'optionMailPT', size: 6 },


                    ],
                },
                esito: {
                    fields : [
                        { name: 'LIVELLO', label: 'Esito', value: '', type: 'optionLivelli', size: 6, isObbligatorio: true },
                        { name: 'TIPO_ESITO', label: 'Tipo esito', value: '', type: 'optionTipoEsito', size: 6, isObbligatorio: false },
                        { name: 'EDIZIONE', label: 'Edizione', value: '', type: 'optionEdizioni', size: 6, isObbligatorio: false },
                        { name: 'DATA_SVOLGIMENTO', label: 'Data', value: '', type: 'calendar', size: 6, isObbligatorio: true },
                    ]
                }
            },
            trienni: []
        }

        // this.columns = [
        //     {
        //         name: 'Title',
        //         selector: 'data',
        //         sortable: true,
        //         cell: row => <div title={row.data}>{'Test finale ' + moment(row.data).format('DD/MM/YYYY')}</div>,
        //     },
        //     {
        //         name: 'Descrizione',
        //         selector: 'descrizione',
        //         sortable: true,
        //         cell: row => <div title={row.descrizione}>{row.descrizione}</div>,
        //     },
        //     {
        //         name: 'Livello',
        //         selector: 'livello',
        //         sortable: true,
        //         cell: row => <div title={row.livello}>{config.decodeLivello[row.livello]}</div>,
        //     },
        //     {
        //         selector: 'livello',
        //         cell: (row, index) => <div><FontAwesomeIcon icon={faPencilAlt} onClick={() => this.openModalMod('test_finale', this.state.studente.test_finale[index], 'M')} className={'modifyCustomButton'} /></div>,
        //     }
        // ];
    }



    componentDidMount() {
        this.callPTAB(null, null);

        // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //     this.setState({ isMobile: true })
        // }

    }

    callPTAB = (idPTAB, emailPTAB) => {
        let ID = idPTAB ? idPTAB : this.props.location.state.ptab.ID;
        let email = emailPTAB ? emailPTAB : this.props.location.state.ptab.EMAIL;
        axios.get(`${process.env.REACT_APP_API_URL}/getPTABbyEMAIL/${email}`, this.props.authParam).then((resp) => {
            resp.data.anagrafica.ID = ID;
            this.callTrienni(email);
            this.setState({ ptab: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        // axios.get(`${process.env.REACT_APP_API_URL}/getNumeroCarriere/${this.props.location.state.studente.COD_FISCALE}`, this.props.authParam).then((resp) => {
        //     this.setState({ numeroCarriere: resp.data })
        // }).catch((err) => {
        //     this.props.setErrors(err);
        // })

        // axios.get(`${process.env.REACT_APP_API_URL}/getDoppiaImmatricolazione/${this.props.location.state.studente.COD_FISCALE}/${ID}`, this.props.authParam).then((resp) => {
        //     this.setState({ idDoppiaImmatricolazione: resp.data.ID, cdlDoppiaImmatricolazione: resp.data.COD_CDL })
        // }).catch((err) => {
        //     this.props.setErrors(err);
        // })

    }

    callTrienni = (email) => {
        axios.get(`${process.env.REACT_APP_API_URL}/getTrienniByEmail/${email}`, this.props.authParam).then((resp) => {
            this.setState({ trienni: resp.data, loadTrienni: true })
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    closeModalConflitto = () => {
        this.setState({ showModalConflitto: false })
    }

    openModalMancante = () => {
        this.setState({ showModalMancante: true })
    }

    closeModalMancante = () => {
        this.setState({ showModalMancante: false })
    }

    openModalConflitto = () => {
        this.setState({ showModalConflitto: true })
    }

    hideAlertConflitto = (isSalvataggio, idPtab, codCdl) => {
        if (isSalvataggio) {
            this.reloadPage(idPtab, codCdl);
        }
        this.setState({ showModalConflitto: false })
    }

    hideAlertMancante = (isSalvataggio) => {
        if (isSalvataggio) {
            this.reloadPage(this.props.location.state.studente.ID, this.props.location.state.studente.COD_CDL);
        }
        this.setState({ showModalMancante: false })
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    goHome = () => {
        this.setParentFilters().then(() => {
            this.props.history.push({ pathname: '/' })
        })
    }

    closeModalAdd = () => {
        this.setState({ showModalAdd: false })
    }

    closeModalMod = (isSalvataggio, idPtab) => {
        let initialDataModal = JSON.parse(JSON.stringify(this.state.initialDataModal));
        if (isSalvataggio) {
            this.reloadPage(idPtab);
        }
        this.setState({ showModalUserData: false, dataModal: initialDataModal })
    }


    openModalMod = (type, params, insertOMod, tipologiaEsito, descrCorsoEsito, edizione) => {
        let dataModal = this.state.dataModal;
        if (insertOMod === 'M') {
            dataModal.id_esito = params.ID_ESITO;
            dataModal[type].fields.map(function (p) {
                if (params[p.name] && p.type === 'calendar') p.value = new Date(params[p.name])
                // else if (p.name === 'STATO') p.value = config.mapIdPtab[params[p.name]];
                else if (params[p.name] || params[p.name] === 0) p.value = params[p.name]
                return p;
            })
        } else if (insertOMod === 'I' && type === 'tentativi_successivi') {
            // dataModal[type].fields.map(function (p) {
            //     if (p.type === 'calendar') p.value = new Date()
            //     p.value = ''
            //     return p;
            // })
        }


        this.setState({ showModalUserData: true, typeModal: type, insertOMod: insertOMod, tipologiaEsito: tipologiaEsito, descrCorsoEsito: descrCorsoEsito, edizione: edizione })
    }

    reloadPage = (idPtab) => {
        this.callPTAB(idPtab);
        this.setState({ reloadPage: true });
        setTimeout(() => {
            this.setState({ reloadPage: false })
        }, 300)
    }

    goToStudentHistory = () => {
        this.props.history.push({ pathname: '/history', state: { cf: this.state.studente.anagrafica.cf, filters: this.props.location.state.filters, isActiveSearch: this.props.location.state.isActiveSearch } })
    }

    eliminaEsito = (fields) => {
        if (window.confirm("Sei sicuro di voler eliminare l'esito")) {
            console.log(fields)
            let params = {
                ID_ESITO: fields.ID_ESITO,
            }

            axios.post(`${process.env.REACT_APP_API_URL}/deleteEsito`, params, this.props.authParam).then((resp) => {
                this.reloadPage(this.props.location.state.ptab.ID);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        }
    }

    isStorico = () => {
        // if (this.state.numeroCarriere) {
        //     if (this.state.numeroCarriere > 1 && this.state.studente.anagrafica.doppia_iscrizione !== 'Y') return true;
        //     else if (this.state.numeroCarriere > 2 && this.state.studente.anagrafica.doppia_iscrizione === 'Y') return true;
        // } return false;
    }

    render() {

        return (
            <div>
                {this.state.showModalConflitto ? <ModalConflitto id_ptab={this.state.ptab.anagrafica.id} authParam={this.props.authParam} hideAlertConflitto={this.hideAlertConflitto} ptab={this.state.ptab} showModalConflitto={this.state.showModalConflitto} closeModalConflitto={this.closeModalConflitto} /> : null}
                {this.state.showModalUserData ?
                    <ModalUserData
                        typeModal={this.state.typeModal}
                        // livelli={this.state.livelli}
                        // statiStudente={this.state.statiStudente}
                        idPtab={this.state.ptab.anagrafica.ID}
                        emailPtab={this.state.ptab.anagrafica.EMAIL}
                        setErrors={this.props.setErrors}
                        authParam={this.props.authParam}
                        insertOMod={this.state.insertOMod}
                        dataModal={this.state.dataModal[this.state.typeModal]}
                        tipologiaEsito={this.state.tipologiaEsito}
                        showModalUserData={this.state.showModalUserData}
                        descrCorsoEsito={this.state.descrCorsoEsito}
                        id_esito={this.state.dataModal.id_esito}
                        edizione={this.state.edizione}
                        closeModalMod={this.closeModalMod} />
                    : null}
                {this.state.ptab && !this.state.reloadPage ? <Fragment>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={this.goHome}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                        <Breadcrumb.Item active={true}>
                            Dettaglio PTAB
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    {this.state.ptab.isConflitto ?
                        <Row className='alertConflitti'>
                            <Col>
                                <Alert className='mt-3' variant={'warning'}>
                                    <div style={{ textAlign: 'center' }}><FontAwesomeIcon size='2x' icon={faExclamationTriangle} /></div>
                                    <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Sono presenti dei conflitti:</div>
                                    <div>In fase di import è cambiato l'Ufficio di appartenenza</div>
                                    <div style={{ textAlign: 'center' }}><Button onClick={this.openModalConflitto} className='mt-2' variant="danger">Gestisci conflitti</Button></div>
                                </Alert>
                            </Col>
                        </Row> : null}
                    <Card className='mt-2 mb-2' border={'dark'}>
                        <Card.Header className='blueUnimiBG'>
                            <Row>
                                <Col xl={8}><h4 style={{ color: 'white' }}><FontAwesomeIcon icon={faIdCard} /> ANAGRAFICA PTAB</h4></Col>
                                {!this.props.isReadOnly ? <Col xl={4} style={{ textAlign: 'right', color: 'white' }}><FontAwesomeIcon onClick={() => this.openModalMod('anagrafica', this.state.ptab.anagrafica, 'M', null, null)} className={'modifyCustomButton cursorHover'} title='Modifica' icon={faPencilAlt} /></Col> : null}
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col xl={6}><span className='customLabel'>Stato PTAB:</span> {this.state.ptab.anagrafica.STATO}</Col>
                                    <Col xl={6}><span className='customLabel'>Matricola:</span> {this.state.ptab.anagrafica.MATRICOLA}</Col>
                                    {/* <Col xl={6}>
                                        <span className='customLabel'>Invio E-mail PT:</span> TO DO
                                    </Col> */}
                                    {/* <Col xl={6}><span className='customLabel'>Codice corso di laurea:</span> TO DO</Col> */}
                                    {/* <Col xl={6}><span className='customLabel'>Corso di laurea:</span> TO DO </Col> */}

                                    {/* <Col xl={6}><span className='customLabel'>Triennio:</span> {this.state.ptab.anagrafica.TRIENNIO} </Col> */}
                                    <Col xl={6}><span className='customLabel'>Cognome:</span> {this.state.ptab.anagrafica.COGNOME}</Col>
                                    <Col xl={6}><span className='customLabel'>Nome:</span> {this.state.ptab.anagrafica.NOME} </Col>
                                    <Col xl={6}><span className='customLabel'>E-mail:</span> {this.state.ptab.anagrafica.EMAIL} </Col>
                                    {/* <Col xl={6}><span className='customLabel'>Livello:</span> {config.decodeLivello[this.state.studente.anagrafica.livello]}</Col> */}
                                    {/* <Col xl={6}><span className='customLabel'>Livello:</span> TO DO </Col> */}
                                    <Col xl={6}><span className='customLabel'>E-mail Responsabile:</span> {this.state.ptab.anagrafica.EMAIL_RESP} </Col>
                                    {/* <Col xl={6}><span className='customLabel'>Data immatricolazione:</span> {moment(this.state.studente.anagrafica.data_iscrizione).format('DD/MM/YYYY')}</Col> */}
                                    <Col xl={6}><span className='customLabel'>Settore:</span> {this.state.ptab.anagrafica.SETTORE} </Col>
                                    <Col xl={6}><span className='customLabel'>Ufficio:</span> {this.state.ptab.anagrafica.UFFICIO} </Col>
                                    <Col xl={6}><span className='customLabel'>Struttura:</span> {this.state.ptab.anagrafica.STRUTTURA} </Col>
                                </Row>
                                {/* {!this.props.isReadOnly ? <Row className='d-flex justify-content-center'>
                                    <Button variant='warning'>Modifica <FontAwesomeIcon icon={faPencilAlt} /></Button>
                                </Row> : null} */}
                            </Card.Text>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                        <Card.Footer className={"text-center"}>
                            {/* {this.state.studente.anagrafica.stato_studente === 'ATTIVO' && this.isStorico() ?
                                <Button className='mr-2' variant="dark" onClick={this.goToStudentHistory} title={'Visualizza storico studente'}>Visualizza storico <FontAwesomeIcon icon={faHistory} /></Button> : this.state.studente.anagrafica.stato_studente !== 'ATTIVO' ? <div className='text-left'>* per le matricole che non risultano in stato <b>ATTIVO</b> la funzionalità storico non è prevista</div> : "Non sono presenti carriere pregresse"}
                            {this.state.studente.anagrafica.doppia_iscrizione === 'Y' ?
                                <div><Button className='mr-2 mt-2' variant="outline-dark" onClick={() => this.reloadPage(this.state.idDoppiaImmatricolazione, this.state.cdlDoppiaImmatricolazione)} title={'Visualizza doppia iscrizione studente'}>Visualizza doppia iscrizione <FontAwesomeIcon icon={faLink} /></Button></div> : null} */}

                        </Card.Footer>
                    </Card>

                    {/* per ogni triennio creo un component AccordionTriennio */}
                    {Object.keys(this.state.trienni).length > 0 && this.state.loadTrienni ? Object.keys(this.state.trienni).map((tr, idx) =>
                        <AccordionTriennio key={idx} triennio={tr} isEditable={this.state.isEditable}>
                            <Row xs={1} md={2} className="g-4">
                                {Array.from({ length: this.state.trienni[tr].length }).map((_, idx) => (
                                    <Col xl={4} key={idx}>
                                        <Card border={'dark'} className='hoverTableShadow'>
                                            <Card.Header className='blueLightUnimiBG'>{this.state.trienni[tr][idx].EDIZIONE}^ Edizione</Card.Header>
                                            <Card.Body>
                                                <Card.Title>{config.decodeFlag[this.state.trienni[tr][idx].LINGUA].nome} <img src={config.decodeFlag[this.state.trienni[tr][idx].LINGUA].logo} /> </Card.Title>
                                                <Card.Text>
                                                    <Table striped bordered>
                                                        <thead style={{fontSize: '12px'}}>
                                                            <tr>
                                                                <th title='Placement Test/Livello'>PT/L.PRE {this.state.trienni[tr][idx].LIVELLI && this.state.trienni[tr][idx].LIVELLI.find(t => (t.TIPO_ESITO === 0 || t.TIPO_ESITO === 3)) ? <span style={{marginLeft: '10%'}}><FontAwesomeIcon className='blueUnimiColor cursorHover' title={'Modifica PT'} icon={faPencilAlt} onClick={() => this.openModalMod('esito', this.state.trienni[tr][idx].LIVELLI.find(t => (t.TIPO_ESITO === 0 || t.TIPO_ESITO === 3)), 'M', 0, this.state.trienni[tr][idx].DESCR_CORSO, this.state.trienni[tr][idx].EDIZIONE)} />  <FontAwesomeIcon style={{color: 'red'}} className='cursorHover' title={'Elimina PT'} onClick={()=> this.eliminaEsito(this.state.trienni[tr][idx].LIVELLI.find(t => (t.TIPO_ESITO === 0 || t.TIPO_ESITO === 3)))} icon={faTrash} /> </span>: null}</th>
                                                                <th title='Test finale'>TF {this.state.trienni[tr][idx].LIVELLI && this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 1) ? <span style={{marginLeft: '10%'}}><FontAwesomeIcon className='blueUnimiColor cursorHover' title={'Modifica TF'} icon={faPencilAlt} onClick={() => this.openModalMod('esito', this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 1), 'M', 1, this.state.trienni[tr][idx].DESCR_CORSO, this.state.trienni[tr][idx].EDIZIONE)} /> <FontAwesomeIcon style={{color: 'red'}} className='cursorHover' title={'Elimina Test Finale'} onClick={()=> this.eliminaEsito(this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 1))} icon={faTrash} /> </span>: null}</th>
                                                                <th title='Test finale 2'>TF 2 {this.state.trienni[tr][idx].LIVELLI && this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 2) ? <span style={{marginLeft: '10%'}}><FontAwesomeIcon className='blueUnimiColor cursorHover' title={'Modifica TF2'} icon={faPencilAlt} onClick={() => this.openModalMod('esito', this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 2), 'M', 2, this.state.trienni[tr][idx].DESCR_CORSO, this.state.trienni[tr][idx].EDIZIONE)} /> <FontAwesomeIcon style={{color: 'red'}} className='cursorHover' title={'Elimina Test finale 2'} onClick={()=> this.eliminaEsito(this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 2))} icon={faTrash} /> </span>: null}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{fontSize: '12px'}}>
                                                            <tr className='text-center'>
                                                                <td>{this.state.trienni[tr][idx].LIVELLI && this.state.trienni[tr][idx].LIVELLI.find(t => (t.TIPO_ESITO === 0 || t.TIPO_ESITO === 3)) ? config.decodeLivello[this.state.trienni[tr][idx].LIVELLI.find(t => (t.TIPO_ESITO === 0 || t.TIPO_ESITO === 3)).LIVELLO] : <span title='Aggiungi esito' className='cursorHover' onClick={() => this.openModalMod('esito', null, 'I',0, this.state.trienni[tr][idx].DESCR_CORSO, this.state.trienni[tr][idx].EDIZIONE)}><FontAwesomeIcon className='blueUnimiColor' icon={faPlusSquare} /></span>}</td>
                                                                <td>{this.state.trienni[tr][idx].LIVELLI && this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 1) ? config.decodeLivello[this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 1).LIVELLO] :  <span title='Aggiungi esito' className='cursorHover' onClick={() => this.openModalMod('esito', null, 'I', 1, this.state.trienni[tr][idx].DESCR_CORSO, this.state.trienni[tr][idx].EDIZIONE)}><FontAwesomeIcon className='blueUnimiColor' icon={faPlusSquare} /></span> }</td>
                                                                <td>{this.state.trienni[tr][idx].LIVELLI && this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 2) ? config.decodeLivello[this.state.trienni[tr][idx].LIVELLI.find(t => t.TIPO_ESITO === 2).LIVELLO] :  <span title='Aggiungi esito' className='cursorHover' onClick={() => this.openModalMod('esito', null, 'I', 2, this.state.trienni[tr][idx].DESCR_CORSO, this.state.trienni[tr][idx].EDIZIONE)}><FontAwesomeIcon className='blueUnimiColor' icon={faPlusSquare} /></span> }</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <span style={{ fontFamily: 'calibri', fontSize: '15px' }}>{this.state.trienni[tr][idx].DESCR_CORSO}</span>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </AccordionTriennio>) :
                        <Row>
                            <Col xl={12} className='mb-3 p-3 text-center'>
                                <span>Per l'anagrafica selezionata non sono presenti Corsi/Esiti. Per aggiungere Corsi prosegui nell'area <b>"Nuovo Corso"</b> nel menù</span>
                            </Col>
                        </Row>

                    }
                </Fragment> : <Loader customStyle={{ marginTop: '5px' }} />}
            </div>)
    }
}

export default withRouter(DettaglioPTAB);