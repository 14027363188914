import React, { Fragment } from 'react';
import logo from '../img/slam_e_unimi.png';
import logoMobile from '../img/logo_slam.png';
import logoPasqua from '../img/Pasqua_SLAM.png';
import { Row, Col, Dropdown,  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faCrown, faSignOutAlt, faUser, faFileCsv, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from "react-router";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isEggVisibile: false
        }
    }

    // goToAdminPage = () => {
    //     this.props.history.push({ pathname: '/adminPage', state: { filters: this.props.filters, isActiveSearch: this.props.isChildSearchActive } })
    // }

    componentDidMount() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({ isMobile: true })
        }

        let today = new Date();
        let year = today.getFullYear();
        let Easter = new Date(year, 2, 25);
        let endEaster = new Date(year, 3, 25);
        if (today >= Easter && today < endEaster) {
            this.setState({ isEggVisibile: true })
        }
    }

    goToNuovoCorso = () => {
        this.props.history.push({ pathname: '/nuovoCorso', state: { filters: this.props.filters } })
    }

    goToImportaPTAB = () => {
        this.props.history.push({ pathname: '/importaPTAB', state: { filters: this.props.filters } })
    }

    goToImportaEsiti = () => {
        this.props.history.push({ pathname: '/importaEsiti', state: { filters: this.props.filters } })
    }

    goToAdminPage = () => {
        this.props.history.push({ pathname: '/adminPage', state: { filters: this.props.filters, } })
    }


    render() {
        return (
            <Fragment>
                <Row className='mb-3' style={{ textAlign: 'center' }}>
                    <Col lg={3}></Col>
                    <Col lg={8} sm={12}><img alt="logo" style={{ height: !this.state.isMobile ? '8rem' : '5rem' }} src={!this.state.isMobile ? logo : logoMobile}></img></Col>
                    <Col lg={1}></Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-end p-2'>
                        <Dropdown>
                            <Dropdown.Toggle style={{ backgroundColor: '#02336A', border: 'black' }} id="dropdown-basic">
                                {/* <FontAwesomeIcon icon={faUser} /> {this.props.user} ({this.props.matricola}) */}
                                <FontAwesomeIcon icon={faUser} /> {this.props.user}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.props.ruolo === 'ADMIN' || this.props.ruolo === 'ADMINISTRATOR' ? <Dropdown.Item onClick={this.goToAdminPage}><FontAwesomeIcon icon={faCrown} /> Admin Page</Dropdown.Item> : null}
                                <Dropdown.Item onClick={this.props.logout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Navbar className='mb-3 customNavBar' collapseOnSelect expand="lg">
                    <Container>
                        <Navbar.Brand>
                            {this.state.isEggVisibile ? <img alt="logoPasqua" style={{ height: '130px', position: 'absolute', bottom: '30px' }} src={logoPasqua}></img> : null}
                            <span className='logoFormazione'>UFF. FORMAZIONE LINGUISTICA</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                            {this.props.ruolo && this.props.ruolo !== 'N/A' ?
                            <Nav>
                                <Nav.Link onClick={this.goToNuovoCorso} eventKey={1}>
                                    Nuovo Corso <FontAwesomeIcon icon={faChalkboardTeacher} />
                                </Nav.Link>
                                <Nav.Link eventKey={2} onClick={this.goToImportaEsiti}>
                                    Importa Esiti <FontAwesomeIcon icon={faFileCsv} />
                                </Nav.Link>
                                <Nav.Link eventKey={3} onClick={this.goToImportaPTAB}>Importa PTAB <FontAwesomeIcon icon={faAddressCard} /></Nav.Link>
                            </Nav> : null}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                {/* <Row className='d-flex justify-content-end'>
                    <label className='mr-2' style={{ fontWeight: 'bold' }}>Utente: </label> {this.props.user}
                </Row>
                <Row className='d-flex justify-content-end'>
                    <Button variant='primary'>Logout <FontAwesomeIcon icon={faSignOutAlt} /></Button>
                </Row> */}
            </Fragment>)
    }
}

export default withRouter(Header);