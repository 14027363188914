import React from 'react';
import { Modal, Button, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import * as config from '../config/config';
import DatePicker from 'react-datepicker'
import { registerLocale } from "react-datepicker";
import moment from 'moment';
import it from "date-fns/locale/it"; // the locale you want
registerLocale("it", it); // register it with the name you want



class ModalUserData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataModal: props.dataModal,
            type: props.typeModal,
            tipologiaEsito: props.tipologiaEsito
        }
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/getStatiPtab`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                this.setState({ stati: resp.data })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getLivelli`, this.props.authParam).then((resp) => {
            if (resp && resp.data.length > 0) {
                this.setState({ livelli: resp.data })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })

    }

    saveData = () => {

        if (this.state.type === 'anagrafica') {
            let params = {
                idPtab: this.props.idPtab,
                fields: this.state.dataModal.fields,
            }

            //convert number to label
            // let id_stato_studente = this.state.dataModal.fields.find(f => f.name === 'stato_studente').value;
            // params.fields.find(f => f.name === 'stato_studente').value = config.mapStatoPtab[id_stato_studente];

            axios.post(`${process.env.REACT_APP_API_URL}/savePtab`, params, this.props.authParam).then((resp) => {
                // let codCdl = resp.data.COD_CDL ? resp.data.COD_CDL : null;
                this.props.closeModalMod(true, this.props.idPtab);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        } else {
            //valutazioni
            let params = [
                {
                    ID_ESITO: this.props.id_esito,
                    ID_PTAB: this.props.idPtab,
                    TIPO_ESITO: this.state.tipologiaEsito,
                    DESCR_CORSO_ESITO: this.props.descrCorsoEsito,
                    DATA_SVOLGIMENTO: this.state.dataModal.fields.find(f => f.name === 'DATA_SVOLGIMENTO').value ? moment(this.state.dataModal.fields.find(f => f.name === 'DATA_SVOLGIMENTO').value).format('DD/MM/YYYY') : null,
                    LIVELLO: this.state.dataModal.fields.find(f => f.name === 'LIVELLO') ? this.state.dataModal.fields.find(f => f.name === 'LIVELLO').value : '',
                    EDIZIONE: this.props.edizione,
                    EMAIL: this.props.emailPtab
                }
            ]

            let check = this.checkFields();

            if (check) {
                if (this.props.insertOMod === 'I') {
                    axios.post(`${process.env.REACT_APP_API_URL}/insertValutazione`, params, this.props.authParam).then((resp) => {
                        this.props.closeModalMod(true, this.props.idPtab);
                    }).catch((err) => {
                        this.props.setErrors(err);
                    })
                } else {
                    axios.post(`${process.env.REACT_APP_API_URL}/updateValutazione`, params, this.props.authParam).then((resp) => {
                        this.props.closeModalMod(true, this.props.idPtab);
                    }).catch((err) => {
                        this.props.setErrors(err);
                    })
                }
            } else {
                alert('inserisci tutti i campi obbligatori')
            }




        }

    }

    onChangeValue = (e, name) => {
        let dataModal = this.state.dataModal;
        let specials = name === 'EMAIL' || name === 'EMAIL_RESP' ? /[*|\":<>[\]{}`\\();&$]/ : /[*|\":<>[\]{}`\\();@&$]/;
        if (!specials.test(e.target.value)) {
            dataModal.fields.find(f => f.name === name).value = e.target.value;
            this.setState({ dataModal: dataModal })
        }

    }

    changeDateImm = (date, name) => {
        let dataModal = this.state.dataModal;
        let dateFormatted = date ? date : '';
        dataModal.fields.find(f => f.name === name).value = dateFormatted;
        this.setState({ dataModal: dataModal })
    }


    checkFields = () => {

        for (let i = 0; i < this.state.dataModal.fields.length; i++) {
            if (this.state.dataModal.fields[i].isObbligatorio && this.state.dataModal.fields[i].value === "") return false;
        }

        return true
    }



    render() {
        return (
            <Modal show={this.props.showModalUserData} onHide={() => this.props.closeModalMod(false)} size="lg">
                <Modal.Header closeButton className='blueUnimiBG'>
                    <Modal.Title style={{ color: 'white' }}>
                        {this.props.insertOMod === 'M' ? 'MODIFICA' : 'INSERIMENTO'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {this.state.dataModal ? this.state.dataModal.fields.map((f, key) =>
                            f.type === 'input' ?
                                <Col key={key} xl={f.size}>
                                    <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            aria-label="Default"
                                            maxLength={f.maxLength ? f.maxLength : null}
                                            onChange={(e) => this.onChangeValue(e, f.name)}
                                            value={f.value}
                                            placeholder={f.label}
                                            aria-describedby="inputGroup-sizing-default"
                                        />
                                    </InputGroup>
                                </Col> :
                                f.type === 'optionLivelli' ?
                                    <Col key={key} xl={f.size}>
                                        <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                        <select className='form-control' onChange={(e) => this.onChangeValue(e, f.name)} value={f.value}>
                                            <option></option>
                                            {this.state.livelli ? this.state.livelli.map(te => <option value={te.ID_LIVELLO} key={te.ID_LIVELLO}>{te.LIVELLO}</option>) : <option></option>}
                                        </select>
                                    </Col> :
                                    f.type === 'optionStatiPtab' ?
                                        <Col key={key} xl={f.size}>
                                            <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                            <select className='form-control' onChange={(e) => this.onChangeValue(e, f.name)} value={f.value}>
                                                <option></option>
                                                {this.state.stati ? this.state.stati.map(stato => <option value={stato.DESCRIZIONE_STATO} key={stato.DESCRIZIONE_STATO}>{stato.DESCRIZIONE_STATO}</option>) : <option></option>}
                                            </select>
                                        </Col> :
                                        f.type === 'optionTipoEsito' ?
                                            <Col key={key} xl={f.size}>
                                                <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                                <select className='form-control' disabled={this.state.tipologiaEsito !== 0} value={f.value}>
                                                    {this.state.tipologiaEsito === 0 ?
                                                        <>
                                                            <option value={0}>PLACEMENT TEST</option>
                                                            <option value={3}>LIVELLO PRECEDENTE</option>
                                                        </>
                                                        :
                                                        <option value={this.state.tipologiaEsito}>{config.decodeTipoEsito[this.state.tipologiaEsito]}</option>}
                                                </select>
                                            </Col> :
                                            f.type === 'optionEdizioni' ?
                                                <Col key={key} xl={f.size}>
                                                    <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                                    <select className='form-control' disabled
                                                        value={this.props.edizione}
                                                    >
                                                        <option value=''></option>
                                                        <option value='1'>I EDIZIONE</option>
                                                        <option value='2'>II EDIZIONE</option>
                                                        <option value='3'>III EDIZIONE</option>
                                                    </select>
                                                </Col> :
                                                <Col key={key} xl={f.size}>
                                                    <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                                    <DatePicker
                                                        className='datepicker'
                                                        closeOnScroll={true}
                                                        onChange={(date) => this.changeDateImm(date, f.name)}
                                                        // minDate={}
                                                        maxDate={new Date()}
                                                        selected={f.value}
                                                        //  startDate={this.props.filters.find(f => f.name === 'startDataImm').value}
                                                        //  endDate={this.props.filters.find(f => f.name === 'endDataImm').value}
                                                        //  selectsStart
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dateFormat="dd/MM/yyyy"
                                                        locale="it"
                                                    />
                                                </Col>

                        ) : null}
                    </Row>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={() => this.props.closeModalMod(false)}>
                        Annulla
                    </Button>
                    <Button onClick={this.saveData} variant="primary">
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>)
    }
}

export default ModalUserData;