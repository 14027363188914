import logoITA from '../img/ita.png';
import logoFRA from '../img/fra.jpg';
import logoENG from '../img/eng.png';
import logoESP from '../img/esp.png';
import logoDEU from '../img/deu.png';
import logoLAT from '../img/lat.png';

export const decodeFlag = {
    "ITA": {logo: logoITA, nome: 'Italiano'},
    "ENG": {logo: logoENG, nome: 'Inglese'},
    "FRA": {logo: logoFRA, nome: 'Francese'},
    "ESP": {logo: logoESP, nome: 'Spagnolo'},
    "DEU": {logo: logoDEU, nome: 'Tedesco'},
    "LAT": {logo: logoLAT, nome: 'Latino'}
}

export const encodeTipoEsito = {
    "PLACEMENT TEST": 0,
    "TEST FINALE": 1,
    "TEST FINALE 2": 2,
    "LIVELLO PRECEDENTE": 3
}

export const encodeLivello = {
    "A1": 0,
    "A2": 1,
    "B1": 2,
    "B1+" : 3,
    "B2": 4,
    "C1": 5,
    "C2": 6,
    "ASSENTE": 7,
    "SUPERATO": 8,
    "NON SUPERATO": 9,
    "NON AMMESSO" : 10,
    "RINUNCIA" : 11
}

export const decodeTipoEsito = {
    0: "PLACEMENT TEST",
    1: "TEST FINALE",
    2: "TEST FINALE 2",
    3: "LIVELLO PRECEDENTE"
}

export const decodeLivello = {
    0: "A1",
    1: "A2",
    2: "B1",
    3: "B1+",
    4: "B2",
    5: "C1",
    6: "C2",
    7: "ASSENTE",
    8: "SUPERATO",
    9: "NON SUPERATO",
    10: "NON AMMESSO",
    11: "RINUNCIA"
}

export const mapIdPtab = {
    "ATTIVO": 0,
    "RINUNCIA": 1,
    "RINUNCIA PREC.": 2,
}

export const mapStatoPtab = {
    0: "ATTIVO",
    1: "RINUNCIA",
    2: "RINUNCIA PREC.",
}