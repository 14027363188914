import React, { Fragment } from 'react';
import { withRouter } from "react-router";
import { Button, Row, Col, Breadcrumb, ProgressBar, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSyncAlt, faDatabase, faCheck } from '@fortawesome/free-solid-svg-icons';
import papa from 'papaparse';
import DataTable from 'react-data-table-component';
import axios from 'axios';

class ImportaPTAB extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            csvLoaded: false,
            taskCompleted: false
        }
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    goHome = () => {
        this.setParentFilters().then(() => {
            this.props.history.push({ pathname: '/' })
        })
    }


    handleFileSelected = (e) => {
        let self = this;
        let obj = {};
        let ptab = {}
        let intestazione = [];
        papa.parse(e.target.files[0], {
            encoding: "ISO-8859-1",
            complete: function (results) {
                if (results.data.length > 0) {
                    for (let i = 0; i < results.data[0].length; i++) {
                        obj[results.data[0][i]] = ''
                    }
                    // intestazione = Object.keys(obj).map(int => ({ name: int.toUpperCase(), selector: int, cell: row => <div title={row[int]}>{row[int]}</div> }));
                    intestazione = [
                        {name: 'MATRICOLA', selector: 'matricola'},
                        {name: 'COGNOME', selector: 'cognome'},
                        {name: 'NOME', selector: 'nome'},
                        {name: 'EMAIL', selector: 'email'},
                    ]
                    results.data.shift();
                    ptab = results.data.map(function (r, idx) {
                        Object.keys(obj).forEach((e, keys) => obj[e] = r[keys])
                        return JSON.parse(JSON.stringify(obj))
                    })
                }

                self.setState({ columnIntestazione: intestazione }, () => {
                    self.setState({ ptabData: ptab, csvLoaded: true })
                })
            },
            skipEmptyLines: true
        })

    }

    saveData = () => {
        //chiamata api per salvare i dati
        this.setState({ isLoading: true, now: 45 })

        
        let params = this.state.ptabData;
        axios.post(`${process.env.REACT_APP_API_URL}/saveAnagrafica`, params, this.props.authParam).then((resp) => {
            if (resp && resp.data.status.toUpperCase() === 'OK') {
                
                setTimeout(()=> {
                    this.setState({isLoading: false, now: 100, taskCompleted: true, ptabData: null})
                }, "3000")
                // this.setState({ percorsoForm: resp.data })
                setTimeout(()=> {
                    this.setState({isLoading: false, now: 100, taskCompleted: null, csvLoaded: false, })
                }, "5000")
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })




  
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goHome}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Importa PTAB
                    </Breadcrumb.Item>
                </Breadcrumb>
                {!this.state.ptabData ? <div className='mt-3'>
                    <h3>IMPORTA PTAB</h3>
                    <p>In questa sezione è possibile caricare il file di anagrafica del PTAB. Il delimitatore sarà il ";"</p>
                </div> : null}

                {!this.state.csvLoaded ? <div><input className='form-control-file' onChange={this.handleFileSelected} type='file' id='files' accept='.csv' required></input></div> : <div></div>}

                {/* Risultati */}
                {this.state.ptabData && this.state.ptabData.length > 0 && !this.state.isLoading  ?
                    <div className='mb-2'>
                        <h3>Anteprima caricamento:</h3>
                        <DataTable
                            className={'tableCustomStyle mt-2'}
                            columns={this.state.columnIntestazione}
                            data={this.state.ptabData}
                            pagination={true}
                            paginationPerPage={5}
                            striped
                            // onChangePage={this.props.onChangePage}
                            highlightOnHover
                            responsive
                            conditionalRowStyles={this.conditionalRowStyles}
                        />
                        <Button onClick={this.saveData} className='mt-3 mr-2' variant='success'>Salva dati DB <FontAwesomeIcon icon={faDatabase} /></Button>
                        <Button onClick={() => this.setState({ csvLoaded: false, ptabData: null })} className='mt-3' variant='primary'>Ricarica csv <FontAwesomeIcon icon={faSyncAlt} /></Button>

                    </div> : null}

                {this.state.isLoading ? <div className='text-center' style={{margin: '30px 20%'}}>
                    <ProgressBar now={this.state.now} label={`${this.state.now}%`} animated ></ProgressBar>
                </div> : null}


                {this.state.taskCompleted ? <Alert variant={'success'}>
                    Anagrafica/Anagrafiche caricata/e correttamente <FontAwesomeIcon icon={faCheck} />
                </Alert> : null}


            </Fragment>)
    }
}


export default withRouter(ImportaPTAB);