import React, { Fragment } from 'react';
import { Row, Col, Card, Accordion, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';



export default class AccordionTriennio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
            isModOn: false,
            triennio: props.triennio,
            initialTriennio: props.triennio
        }
    }

    handleAccordion = () => {
        let clicked = !this.state.clicked;
        let classIcon = clicked ? 'moveIconUp' : 'moveIconDown';
        this.setState({ moveClass: classIcon, clicked: clicked })
    }

    activeMod = (e) => {
        e.stopPropagation();
        this.setState({ isModOn: !this.state.isModOn })
    }

    onChangeValue = (e) => {
        let specials = /[*|\":<>[\]{}`\\()';&$]/;
        if (!specials.test(e.target.value)) {
            let triennio = this.state.triennio;
            triennio = e.target.value;
            this.setState({ triennio: triennio })
        }

    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            //TO DO: chiamata api per salvare Triennio
            let params = {
                triennio: this.state.initialTriennio,
                lingua: this.props.linguaTriennio,
                newTriennio: this.state.triennio
            }
            axios.post(`${process.env.REACT_APP_API_URL}/updateTriennioName`, params, this.props.authParam).then(() => {
                this.setState({ isModOn: !this.state.isModOn })
            }).catch((err) => {
                this.props.setErrors(err);
            })
        }
    }


    render() {
        return (
            <Accordion className='mt-2 mb-2' id={'test'} defaultActiveKey={this.props.defaultVisibleAccordion}>
                <Card>
                    <Accordion.Toggle as={'a'} onClick={this.handleAccordion} variant="link" eventKey="0">
                        <Card.Header className='cursorHover blueUnimiBG'>
                            <Row className='d-flex justify-content-between cardTitle'>
                                <Col><span><FontAwesomeIcon icon={faGraduationCap} /> {this.state.isModOn ? <InputGroup onClick={(e) => { e.stopPropagation(); }}>
                                    <FormControl
                                        aria-label="Default"
                                        onKeyDown={this.onKeyDown}
                                        onChange={this.onChangeValue}
                                        value={`${this.state.triennio}`}
                                        title={'Premi invio per salvare'}
                                        aria-describedby="inputGroup-sizing-default"
                                    />
                                </InputGroup> : `Triennio ${this.state.triennio}`} </span></Col>
                                <Col className='text-right'>
                                    {this.props.isEditable ? <FontAwesomeIcon onClick={this.activeMod} className='mr-3' icon={faPencilAlt} /> : null}
                                    <FontAwesomeIcon icon={faPlus} className={this.state.moveClass} />
                                </Col>
                            </Row>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" id={'test2'}>
                        <Fragment>
                            <Card.Body>
                                {this.props.children}
                                {this.props.triennio.nEdizioni < 3 ? <Row className='text-center mt-2'>
                                    <Col>
                                        <Button>Aggiungi Edizione</Button>
                                    </Col>
                                </Row> : null}
                            </Card.Body>
                            {/* <Card.Footer>modifica Triennio</Card.Footer> */}
                        </Fragment>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}